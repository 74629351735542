.campaigns-detail {
    position: relative;
    top: 108px;
}

.campaigns-detail .box-wrapper {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, .06);
    display: flex;
    border-radius: 8px;
    padding: 32px 32px 100px 25px;
    position: relative;
    margin-bottom: 26px;
    border: 1px solid #f4f4f4;
}

.detail-con {
    max-width: 1000px;
    margin: 0 auto;
    background-color: #fff;
    padding: 15px 0px 100px 0px;
}

.detail-con .header-img {
    width: 380px;
}

.detail-con .header-img img {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, .14);
    cursor: pointer;

}

.detail-info {
    padding: 0 50px 40px 25px;
    position: relative;
    flex: 1;
}

.title {
    display: flex;
    justify-content: space-between;
    margin: 0px 0 10px 0;
}

.title-text {
    color: #333;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}

.title .like-it {
    cursor: pointer;
}

.title .like-it span {
    position: relative;
    top: -3px;
}

.title .liked {
    color: #f0774a;
}

.cam-brief {
    color: #333;
    font-size: 13px;
    margin-bottom: 15px;

}

.campaign-id {
    color: #999;
    font-size: 12px;
    margin-bottom: 8px;
}

.campaign-id-con {
    margin-left: 10px;
}

.campaign-type {
     color: #999;
    font-size: 12px;
    margin-bottom: 24px;
}

.type-con {
    margin-left: 10px;
}

.campaign-duration {
    font-size: 13px;
    margin-bottom: 8px;

}

.duration-time {
    margin-left: 12px;
}

.distribution-country {
    font-size: 13px;
    margin-bottom: 8px;
    display: flex;
}

.country-tag {
    font-size: 13px;
}
.detail-info .country-tag {
    display: flex;
}
.country-tag .country-item {
    padding-left: 20px;
}
.country-item .national-flag{
    width: 20px;
    border-radius: 100px;
}

.tag-item {
    padding: 1px 0 1px 7px;
    font-size: 12px;
    vertical-align: middle;
}

.free-sample {
    font-size: 13px;
    margin-bottom: 12px;
}
.ic-sample-icon{
   width: 18px;
   height: 18px;
   margin-left: 14px;
   margin-right: 10px;
   margin-top: -4px;
}  
.free-sam-label {
    vertical-align: text-bottom;
}
.free-sam-yes {
    /* margin-left: 10px; */
    color: rgb(183,155,104);
    vertical-align: text-bottom;
}

.average-earn {
    font-size: 13px;
    margin-bottom: 10px;
    position: absolute;
}

.average-earn .reward-t {
    font-size: 17px;
    font-weight: 600;
}

.average-earn .aver-2 {
    font-size: 18px;
    color: #f0774a;
    font-weight: 700;
    margin-left: 20px;

}

.average-earn .aver-3 {
    font-size: 24px;
    color: #f0774a;
    font-weight: 700;
    margin-left: 34px;
    position: relative;
    top: 2px;
}

.earn-money {
    color: #f0774a;
    margin-left: 10px;
    font-size: 24px;
    font-weight: 600;
}

.total-sales {
    margin-left: 10px;
    color: #F0774A;
    font-weight: 600;
    font-size: 24px;
}

.total-sales .total-sales-txt {
    font-size: 17px;
}

.earn-money .dollar {
    font-size: 18px;
}
.earn-money .money-num {
    font-weight: 600;
}

.box-wrapper .like-it {
    border-radius: 50px;
    font-size: 14px;
    color: #999;
    display: inline-block;
    padding: 1px 0px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    bottom: 60px;
    left: 49px;
}


.like-it .anticon-heart {
    position: relative;
    top: -2px;
    margin-right: 7px;
}

.detail-con .liked .like-icon {
    color: #f0774a;
    /* border-color: #f0774a; */
}

.apply-now-btn {
    width: 172px;
    height: 57px;
    background: url('../../imgs/ic_join.png') center no-repeat;
    font-size: 16px;
    color: #fff;
    display: inline-block;
    padding: 10px 30px;
    background-size: contain;
    position: absolute;
    bottom: 10px;
    text-align: center;
    right: -18px;
    cursor: pointer;
}

.apply-now-btn-dis {
    width: 172px;
    height: 57px;
    background: url('../../imgs/join_now_dis.png') center no-repeat;
    background-size: contain;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    display: inline-block;
    padding: 10px 30px;
    background-size: contain;
    position: absolute;
    bottom: 40px;
    text-align: center;
    right: -18px;
    cursor: not-allowed;
}


.c-join-btn-w {
    display: flex;
    justify-content: center;
    align-items: center;

}

.c-join-now-btn {
    /* background: linear-gradient(to right, #F79C37, #F0774A); */
    background-color: #F0774A;
    border-radius: 100px;
    padding: 12px 150px;
    color: #fff;
    border: none;
    cursor: pointer;
}

.c-join-now-btn-dis {
    background-color: #ccc;
    border-radius: 100px;
    padding: 12px 150px;
    color: #fff;
    border: none;
    cursor: pointer;
}


.join-now {
    width: 168px;
    height: 53px;
    background: url('../../imgs/ic_join.png');
    background-size: cover;
    position: absolute;
    right: -18px;
    bottom: 40px;
    color: #fff;
    line-height: 44px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;

}

.join-now-dis {
    width: 168px;
    height: 53px;
    background: url('../../imgs/join_now_dis.png');
    background-size: 168px 53px;
    position: absolute;
    right: -18px;
    bottom: 10px;
    color: #fff;
    line-height: 44px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;

}




.campaign-step {
    display: flex;
    padding: 40px 0 0 0px;
    justify-content: center;
    align-items: center;

}



.box-wrapper-s {
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    display: flex;
    border-radius: 8px;
    padding: 30px 25px 35px 0;
    margin-bottom: 30px;
}
.step-name {
    width: 160px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 17px;
    position: relative;
}

.step-name .gray-line {
    width: 1px;
    height: 70%;
    border-right: 1px solid #ddd;
    right: 0;
    position: absolute;
}

.step-detail {
    display: flex;
    flex-direction: row;
    flex: 1;
    
}
.step-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 30px 0 30px;
  position: relative;
  flex: 1;
}
.step-item .step-arrow {
    width: 26px;
    height: 15px;
    position: absolute;
    top: 30px;
    right: -10px;
}

.step-item  .step-arrow-b {
    width: 32px;
    height: 20px;
    position: absolute;
    top: 30px;
    right: -10px;
}

.step-item img {
    width: 50px;
    height: 50px;
}
.step-item .step-t {
   font-weight: 600;
   margin: 4px 0 4px 0;
   font-size: 14px;
}
.step-item .step-des {
   font-size: 13px;
   color: #aaa;
   text-align: center;
   height: 32px;
   margin-top: 10px;
}
.step-item .step-end-time {
    color: #f0774a;
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
}




.campaign-description {
    padding: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    border-radius: 8px;
    margin-bottom: 40px;
    overflow: hidden;
    white-space: pre-wrap;
}

.campaign-description p {
    min-height: 20px;
    margin-bottom: 2px;
}
 

.campaign-description ol,
.campaign-description ul {
   list-style: inside; 
}


.campaign-duration img {
    max-width: 100%;
    max-height: 100%;
}

.des-title {
    font-weight: 500;
}

.des-content {
    padding-left: 100px;
    padding-right: 100px;
    font-size: 13px;
}

.apply-btn-con {
    padding-bottom: 100px;
}

.apply-btn {
    display: block;
    background-color: #f0774a;
    color: #fff;
    font-size: 13px;
    height: 30px;
    line-height: 30px;
    width: 200px;
    text-align: center;
    margin: 30px auto 60px;

}


.campaigns-detail .campaign-des{
    box-shadow: 0px 10px 20px rgba(0, 0, 0, .06);
    border-radius: 8px;
    padding: 32px 32px 32px 25px;
    position: relative;
    margin-bottom: 26px;
    border: 1px solid #f4f4f4;
}
.campaign-des-item {
    margin-bottom: .4rem;
    border-bottom: 1px solid #eee;
    padding: .0rem .4rem .4rem .4rem;

}

.campaign-des-item-s{
    padding: .0rem .4rem 0rem .4rem;
}

.step-content {
    display: flex;
    margin-top: 20px;
}
.step-content-item {
    flex: 1;
    margin-left: 15px;
    height: 140px;
    background-color: rgb(246,244,242);
    border-radius: 10px;
    padding: 13px 10px 10px 10px;

}
.step-c-h {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-weight: 600;
}
.step-c-img {
    width: 30px;
}
.step-c-h .step-t {
    position: relative;
}
.step-c-h .step-t::after {
    content: '';
    position: absolute;
    width: 22px;
    height: 2px;
    background-color: #f0774a;
    bottom: -10px;
    border-radius: 2px;
    left: 0;
}
.step-c-h-body{
    font-size: 12px;
    color: #666;
    margin-top: 20px;
}
.step-c-h-time{
    color: #f0774a;
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;

}


.title-h {
    position: relative;
    font-size: 18px;
    font-weight: 600;
    padding-left: 10px;
}

.title-h::after {
    position: absolute;
    height: 22px;
    width: 3px;
    border-radius: 100px;
    background-color: #f0774a;
    left: -3px;
    content: '';
    top: 50%;
    transform: translateY(-50%);
}
.rewards-wrapper{
    padding: .0rem .4rem 0 .4rem;
}

.rewards-des {
   color: #666;
   font-size: 16px;
   margin: 15px 0 14px 0;
}
.rewards-table {
    border: 1px solid #f8f9fc;
}
.rewards-table-item {
    padding: 5px 41px 5px 41px;
    color: #9B99A2;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.rewards-table-gray-item {
    background-color: #f5f4f3;
}
.rewards-table-header{
    color: #1E191A;
}

.des-detail {
    padding: 0 8px 0 8px;
}

.de-title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
}

.dee-title {
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
}

.des-detail ul {
    list-style: unset;
    padding-left: 15px;
    font-size: 13px;
    font-weight: 600;
    color: #666;
}

.des-detail ul li {
    margin-top: 13px;
}

.box-wrapper-column {
    box-shadow: 0px 10px 20px rgb(0 0 0 / 6%);
    display: flex;
    border-radius: 8px;
    padding: 32px 50px 18px 50px;
    position: relative;
    margin-bottom: 26px;
    border: 1px solid #f4f4f4;
    flex-direction: column;
}
.sample-tt {
    font-size:19px;
    font-weight: 600;
    margin-bottom: 8px;
}
.sample-title {
    font-size: 16px;
    /* font-weight: 600; */
    margin-bottom: 20px; 

}

.sample-dis {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */

}
.sample-item {
    text-align: center;
    margin-bottom: 20px;
    border:1px solid #eee;
    padding: 0px 0 6px 0;
    border-top-right-radius:8px;
    border-top-left-radius:8px;
    width: 170px;
    margin-right: 20px;

}
.sample-img {
   width: 160px;
   height: 160px;
   overflow: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   border-raduis:4px;
   
}

.sample-img img {
    /* border-top-right-radius:8px;
    border-top-left-radius:8px; */

}
.sample-img img:hover {
    transform: scale(1.5);
    transition: all 1s;
}
.sample-name {
    font-size: 15px;
    font-weight: 600;
    color: #666;
    margin-bottom: 8px;
    margin-top: 8px;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
    margin: 0 auto;
    text-overflow: ellipsis;

}
.sample-apply-btn {
    display: inline-block;
    padding: 5px 12px;
    color: #fff;
    margin: 5px auto;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    width: 170px;
    border-radius: 5px;
    background: -webkit-linear-gradient(left,#FBB86A,#F0774A);

}
.sample-apply-btn-did{
    display: inline-block;
    padding: 5px 15px;
    background-color: #ddd;
    color: #fff;
    margin: 5px auto;
    font-size: 14px;
    border-radius: 2px;
    width: 170px;
    text-align: center;

}

.follow-rule {
    margin-top:10px;
}
.follow-rule  ul li{
  margin-bottom:6px;
}

 .add-form .address-save-btn-disable {
    width: 100%;
    background:#ccc!important;
    border-radius: 5px;
    color: #fff;
    border: none;
}
 .add-form .address-save-btn-disable:hover{
     background:#ccc!important;
     color:#fff;

 }

 .add-form .download-app-wrapper {
     border-radius: 40px;
     padding: 10px 28px 0;
     color: #333;
     text-align: center;
     position: relative;
 }
 .download-app-wrapper .download-app-logo {
     width: 180px;
 }
 .download-app-wrapper .download-title {
     text-align: center;   
     margin-bottom: 30px;
     color: #999;
 }
 .download-app-wrapper .ios-app,
 .download-app-wrapper .and-app {
    width: 100%; 
    display: inline-block;
    margin-bottom: 10px;
 }
 .download-app-wrapper .close-icon {
     position: absolute;
     top: -10px;
     right: -10px;
     width: 12px;
     height: 12px;
 }
 .download-modal .ant-modal-content{
     border-radius: 7px;
 }






@media only screen and (max-width: 640px) {

    .title {
        margin: 18px 0 18px 0;
    }

    .des-content {
        font-size: 13px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .campaigns-detail .box-wrapper{
        flex-direction: column-reverse;
        padding-top: 40px;
        padding-bottom: 30px;
        margin-bottom: 10px;
    }
    .detail-con .header-img{
        width: 100%;
        min-height: 180px;
    }
    .join-now-dis{
        right: 0;
    }
    .join-now{
        right: 0;
    }
    .box-wrapper-s{
        flex-direction: column;
        margin-bottom: 10px;
        padding-right: 0;
    }
    .step-detail{
        flex-direction: column;
    }
    .detail-info{
        padding: 0 25px 80px 0px;
    }
    .title-text{
        font-size: 17px;
    }
    .campaigns-detail .campaign-duration{
        font-size: 13px;
    }
    .campaigns-detail .free-sample{
        margin-bottom: 10px;
    }
    .box-wrapper .like-it{
        bottom: 30px;
        left: 27px;
    }
    .join-now, .join-now-dis {
        width: 128px;
        height: 42px;
        font-size: 15px;
        line-height: 38px;
        bottom: 20px;
    }
    .c-join-now-btn{
        padding: 6px 60px;
    }

    .step-item .step-arrow{
        display: none;
    }
    .step-item .step-arrow-b{
        display: none;
    }
    .step-name{
        justify-content: unset;
        margin-left: 25px;
    }
    .step-name .gray-line{
        display: none;
    }
    .step-item{
        margin-bottom: 13px;
    }
    .sample-apply-btn {
        width: 31%;
        padding: 3px;
    }
}