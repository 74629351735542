.campaigns-detail {
    position: relative;
    top: 108px;
}

.campaigns-detail .box-wrapper {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, .06);
    display: flex;
    border-radius: 8px;
    padding: 32px 32px 100px 25px;
    position: relative;
    margin-bottom: 26px;
    border: 1px solid #f4f4f4;
}

.detail-con {
    max-width: 1000px;
    margin: 0 auto;
    background-color: #fff;
    padding: 15px 0px 100px 0px;
}

.detail-con .header-img {
    width: 380px;
}

.detail-con .header-img img {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, .14);
    cursor: pointer;

}

.detail-info {
    padding: 0 50px 40px 25px;
    position: relative;
    flex: 1 1;
}

.title {
    display: flex;
    justify-content: space-between;
    margin: 0px 0 10px 0;
}

.title-text {
    color: #333;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}

.title .like-it {
    cursor: pointer;
}

.title .like-it span {
    position: relative;
    top: -3px;
}

.title .liked {
    color: #f0774a;
}

.cam-brief {
    color: #333;
    font-size: 13px;
    margin-bottom: 15px;

}

.campaign-id {
    color: #999;
    font-size: 12px;
    margin-bottom: 8px;
}

.campaign-id-con {
    margin-left: 10px;
}

.campaign-type {
     color: #999;
    font-size: 12px;
    margin-bottom: 24px;
}

.type-con {
    margin-left: 10px;
}

.campaign-duration {
    font-size: 13px;
    margin-bottom: 8px;

}

.duration-time {
    margin-left: 12px;
}

.distribution-country {
    font-size: 13px;
    margin-bottom: 8px;
    display: flex;
}

.country-tag {
    font-size: 13px;
}
.detail-info .country-tag {
    display: flex;
}
.country-tag .country-item {
    padding-left: 20px;
}
.country-item .national-flag{
    width: 20px;
    border-radius: 100px;
}

.tag-item {
    padding: 1px 0 1px 7px;
    font-size: 12px;
    vertical-align: middle;
}

.free-sample {
    font-size: 13px;
    margin-bottom: 12px;
}
.ic-sample-icon{
   width: 18px;
   height: 18px;
   margin-left: 14px;
   margin-right: 10px;
   margin-top: -4px;
}  
.free-sam-label {
    vertical-align: text-bottom;
}
.free-sam-yes {
    /* margin-left: 10px; */
    color: rgb(183,155,104);
    vertical-align: text-bottom;
}

.average-earn {
    font-size: 13px;
    margin-bottom: 10px;
    position: absolute;
}

.average-earn .reward-t {
    font-size: 17px;
    font-weight: 600;
}

.average-earn .aver-2 {
    font-size: 18px;
    color: #f0774a;
    font-weight: 700;
    margin-left: 20px;

}

.average-earn .aver-3 {
    font-size: 24px;
    color: #f0774a;
    font-weight: 700;
    margin-left: 34px;
    position: relative;
    top: 2px;
}

.earn-money {
    color: #f0774a;
    margin-left: 10px;
    font-size: 24px;
    font-weight: 600;
}

.total-sales {
    margin-left: 10px;
    color: #F0774A;
    font-weight: 600;
    font-size: 24px;
}

.total-sales .total-sales-txt {
    font-size: 17px;
}

.earn-money .dollar {
    font-size: 18px;
}
.earn-money .money-num {
    font-weight: 600;
}

.box-wrapper .like-it {
    border-radius: 50px;
    font-size: 14px;
    color: #999;
    display: inline-block;
    padding: 1px 0px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    bottom: 60px;
    left: 49px;
}


.like-it .anticon-heart {
    position: relative;
    top: -2px;
    margin-right: 7px;
}

.detail-con .liked .like-icon {
    color: #f0774a;
    /* border-color: #f0774a; */
}

.apply-now-btn {
    width: 172px;
    height: 57px;
    background: url(/static/media/ic_join.a8acb4c5.png) center no-repeat;
    font-size: 16px;
    color: #fff;
    display: inline-block;
    padding: 10px 30px;
    background-size: contain;
    position: absolute;
    bottom: 10px;
    text-align: center;
    right: -18px;
    cursor: pointer;
}

.apply-now-btn-dis {
    width: 172px;
    height: 57px;
    background: url(/static/media/join_now_dis.4b72a7b9.png) center no-repeat;
    background-size: contain;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    display: inline-block;
    padding: 10px 30px;
    background-size: contain;
    position: absolute;
    bottom: 40px;
    text-align: center;
    right: -18px;
    cursor: not-allowed;
}


.c-join-btn-w {
    display: flex;
    justify-content: center;
    align-items: center;

}

.c-join-now-btn {
    /* background: linear-gradient(to right, #F79C37, #F0774A); */
    background-color: #F0774A;
    border-radius: 100px;
    padding: 12px 150px;
    color: #fff;
    border: none;
    cursor: pointer;
}

.c-join-now-btn-dis {
    background-color: #ccc;
    border-radius: 100px;
    padding: 12px 150px;
    color: #fff;
    border: none;
    cursor: pointer;
}


.join-now {
    width: 168px;
    height: 53px;
    background: url(/static/media/ic_join.a8acb4c5.png);
    background-size: cover;
    position: absolute;
    right: -18px;
    bottom: 40px;
    color: #fff;
    line-height: 44px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;

}

.join-now-dis {
    width: 168px;
    height: 53px;
    background: url(/static/media/join_now_dis.4b72a7b9.png);
    background-size: 168px 53px;
    position: absolute;
    right: -18px;
    bottom: 10px;
    color: #fff;
    line-height: 44px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;

}




.campaign-step {
    display: flex;
    padding: 40px 0 0 0px;
    justify-content: center;
    align-items: center;

}



.box-wrapper-s {
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    display: flex;
    border-radius: 8px;
    padding: 30px 25px 35px 0;
    margin-bottom: 30px;
}
.step-name {
    width: 160px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 17px;
    position: relative;
}

.step-name .gray-line {
    width: 1px;
    height: 70%;
    border-right: 1px solid #ddd;
    right: 0;
    position: absolute;
}

.step-detail {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    
}
.step-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 30px 0 30px;
  position: relative;
  flex: 1 1;
}
.step-item .step-arrow {
    width: 26px;
    height: 15px;
    position: absolute;
    top: 30px;
    right: -10px;
}

.step-item  .step-arrow-b {
    width: 32px;
    height: 20px;
    position: absolute;
    top: 30px;
    right: -10px;
}

.step-item img {
    width: 50px;
    height: 50px;
}
.step-item .step-t {
   font-weight: 600;
   margin: 4px 0 4px 0;
   font-size: 14px;
}
.step-item .step-des {
   font-size: 13px;
   color: #aaa;
   text-align: center;
   height: 32px;
   margin-top: 10px;
}
.step-item .step-end-time {
    color: #f0774a;
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
}




.campaign-description {
    padding: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    border-radius: 8px;
    margin-bottom: 40px;
    overflow: hidden;
    white-space: pre-wrap;
}

.campaign-description p {
    min-height: 20px;
    margin-bottom: 2px;
}
 

.campaign-description ol,
.campaign-description ul {
   list-style: inside; 
}


.campaign-duration img {
    max-width: 100%;
    max-height: 100%;
}

.des-title {
    font-weight: 500;
}

.des-content {
    padding-left: 100px;
    padding-right: 100px;
    font-size: 13px;
}

.apply-btn-con {
    padding-bottom: 100px;
}

.apply-btn {
    display: block;
    background-color: #f0774a;
    color: #fff;
    font-size: 13px;
    height: 30px;
    line-height: 30px;
    width: 200px;
    text-align: center;
    margin: 30px auto 60px;

}


.campaigns-detail .campaign-des{
    box-shadow: 0px 10px 20px rgba(0, 0, 0, .06);
    border-radius: 8px;
    padding: 32px 32px 32px 25px;
    position: relative;
    margin-bottom: 26px;
    border: 1px solid #f4f4f4;
}
.campaign-des-item {
    margin-bottom: .4rem;
    border-bottom: 1px solid #eee;
    padding: .0rem .4rem .4rem .4rem;

}

.campaign-des-item-s{
    padding: .0rem .4rem 0rem .4rem;
}

.step-content {
    display: flex;
    margin-top: 20px;
}
.step-content-item {
    flex: 1 1;
    margin-left: 15px;
    height: 140px;
    background-color: rgb(246,244,242);
    border-radius: 10px;
    padding: 13px 10px 10px 10px;

}
.step-c-h {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-weight: 600;
}
.step-c-img {
    width: 30px;
}
.step-c-h .step-t {
    position: relative;
}
.step-c-h .step-t::after {
    content: '';
    position: absolute;
    width: 22px;
    height: 2px;
    background-color: #f0774a;
    bottom: -10px;
    border-radius: 2px;
    left: 0;
}
.step-c-h-body{
    font-size: 12px;
    color: #666;
    margin-top: 20px;
}
.step-c-h-time{
    color: #f0774a;
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;

}


.title-h {
    position: relative;
    font-size: 18px;
    font-weight: 600;
    padding-left: 10px;
}

.title-h::after {
    position: absolute;
    height: 22px;
    width: 3px;
    border-radius: 100px;
    background-color: #f0774a;
    left: -3px;
    content: '';
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.rewards-wrapper{
    padding: .0rem .4rem 0 .4rem;
}

.rewards-des {
   color: #666;
   font-size: 16px;
   margin: 15px 0 14px 0;
}
.rewards-table {
    border: 1px solid #f8f9fc;
}
.rewards-table-item {
    padding: 5px 41px 5px 41px;
    color: #9B99A2;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.rewards-table-gray-item {
    background-color: #f5f4f3;
}
.rewards-table-header{
    color: #1E191A;
}

.des-detail {
    padding: 0 8px 0 8px;
}

.de-title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
}

.dee-title {
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
}

.des-detail ul {
    list-style: unset;
    padding-left: 15px;
    font-size: 13px;
    font-weight: 600;
    color: #666;
}

.des-detail ul li {
    margin-top: 13px;
}

.box-wrapper-column {
    box-shadow: 0px 10px 20px rgb(0 0 0 / 6%);
    display: flex;
    border-radius: 8px;
    padding: 32px 50px 18px 50px;
    position: relative;
    margin-bottom: 26px;
    border: 1px solid #f4f4f4;
    flex-direction: column;
}
.sample-tt {
    font-size:19px;
    font-weight: 600;
    margin-bottom: 8px;
}
.sample-title {
    font-size: 16px;
    /* font-weight: 600; */
    margin-bottom: 20px; 

}

.sample-dis {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */

}
.sample-item {
    text-align: center;
    margin-bottom: 20px;
    border:1px solid #eee;
    padding: 0px 0 6px 0;
    border-top-right-radius:8px;
    border-top-left-radius:8px;
    width: 170px;
    margin-right: 20px;

}
.sample-img {
   width: 160px;
   height: 160px;
   overflow: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   border-raduis:4px;
   
}

.sample-img img {
    /* border-top-right-radius:8px;
    border-top-left-radius:8px; */

}
.sample-img img:hover {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    transition: all 1s;
}
.sample-name {
    font-size: 15px;
    font-weight: 600;
    color: #666;
    margin-bottom: 8px;
    margin-top: 8px;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
    margin: 0 auto;
    text-overflow: ellipsis;

}
.sample-apply-btn {
    display: inline-block;
    padding: 5px 12px;
    color: #fff;
    margin: 5px auto;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    width: 170px;
    border-radius: 5px;
    background: -webkit-linear-gradient(left,#FBB86A,#F0774A);

}
.sample-apply-btn-did{
    display: inline-block;
    padding: 5px 15px;
    background-color: #ddd;
    color: #fff;
    margin: 5px auto;
    font-size: 14px;
    border-radius: 2px;
    width: 170px;
    text-align: center;

}

.follow-rule {
    margin-top:10px;
}
.follow-rule  ul li{
  margin-bottom:6px;
}

 .add-form .address-save-btn-disable {
    width: 100%;
    background:#ccc!important;
    border-radius: 5px;
    color: #fff;
    border: none;
}
 .add-form .address-save-btn-disable:hover{
     background:#ccc!important;
     color:#fff;

 }

 .add-form .download-app-wrapper {
     border-radius: 40px;
     padding: 10px 28px 0;
     color: #333;
     text-align: center;
     position: relative;
 }
 .download-app-wrapper .download-app-logo {
     width: 180px;
 }
 .download-app-wrapper .download-title {
     text-align: center;   
     margin-bottom: 30px;
     color: #999;
 }
 .download-app-wrapper .ios-app,
 .download-app-wrapper .and-app {
    width: 100%; 
    display: inline-block;
    margin-bottom: 10px;
 }
 .download-app-wrapper .close-icon {
     position: absolute;
     top: -10px;
     right: -10px;
     width: 12px;
     height: 12px;
 }
 .download-modal .ant-modal-content{
     border-radius: 7px;
 }






@media only screen and (max-width: 640px) {

    .title {
        margin: 18px 0 18px 0;
    }

    .des-content {
        font-size: 13px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .campaigns-detail .box-wrapper{
        flex-direction: column-reverse;
        padding-top: 40px;
        padding-bottom: 30px;
        margin-bottom: 10px;
    }
    .detail-con .header-img{
        width: 100%;
        min-height: 180px;
    }
    .join-now-dis{
        right: 0;
    }
    .join-now{
        right: 0;
    }
    .box-wrapper-s{
        flex-direction: column;
        margin-bottom: 10px;
        padding-right: 0;
    }
    .step-detail{
        flex-direction: column;
    }
    .detail-info{
        padding: 0 25px 80px 0px;
    }
    .title-text{
        font-size: 17px;
    }
    .campaigns-detail .campaign-duration{
        font-size: 13px;
    }
    .campaigns-detail .free-sample{
        margin-bottom: 10px;
    }
    .box-wrapper .like-it{
        bottom: 30px;
        left: 27px;
    }
    .join-now, .join-now-dis {
        width: 128px;
        height: 42px;
        font-size: 15px;
        line-height: 38px;
        bottom: 20px;
    }
    .c-join-now-btn{
        padding: 6px 60px;
    }

    .step-item .step-arrow{
        display: none;
    }
    .step-item .step-arrow-b{
        display: none;
    }
    .step-name{
        justify-content: unset;
        margin-left: 25px;
    }
    .step-name .gray-line{
        display: none;
    }
    .step-item{
        margin-bottom: 13px;
    }
    .sample-apply-btn {
        width: 31%;
        padding: 3px;
    }
}
.campaigns-detail {
}

.campaigns-detail .pending {
  /* display: flex; */
  /* align-items: end; */
  /* margin-top: 10px; */
  /* flex-direction: column; */

}
.pending .pending-ic {
  display: flex;
  align-items: center;
}
.task-detail .box-wrapper {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, .06);
  display: flex;
  border-radius: 8px;
  padding: 32px;
  position: relative;
  margin-bottom: 30px;
  border: 1px solid #f4f4f4;
}

.box-wrapper-c {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, .06);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 25px;
  position: relative;
  margin-bottom: 30px;
  border: 1px solid #f4f4f4;
}

.campaign-task-step {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 22px 90px 22px 26px;

}


.campaign-task-step .center {
  /* left: 110px; */
  margin-left: 30px;
}

.campaign-task-step .img-evidence{
  margin-top: 24px;
  position: relative;

}


.step-1-1 {
  display: flex;
  /* align-items: center; */
}

.step-1 .content-de {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}
.step-1 .how-to-use{
  font-size: 12px;
  color: #ccc;
  margin-top: 6px;
}
.how-to-pro{
  font-size: 12px;
  color: #ccc;
  margin-top: 6px;
}
.mini-step-arrow{
  width: 12px;
  height: 7.5px;
  margin-left: 6px;
}

.step1-center {
  flex: 1 1;
}

.apply-btn-task-dis{
  height: 35px;
  border-radius: 40px;
  background-color: #eee;
  color: #fff;
  text-align: center;
  line-height: 35px;
  cursor: not-allowed;
  font-size: 15px;
  max-width: 240px;
}

.apply-btn-task {
  display: inline-block;
  /* height: 45px; */
  padding: 9px 20px;
  border-radius: 40px;
  background: linear-gradient(to right, #F79C37, #F0774A);
  color: #fff;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  min-width: 170px;
  box-shadow: 0 0 0.61538462em rgb(0 0 0 / 40%);
  
}

.code-coupon {
  width: 208px;
  height: 86px;
  background: url(/static/media/coupon.bbc3e86c.png) center no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.code-coupon .code-num {
  color: #F0774A;
  font-weight: bolder;
  font-size: 16px;

}
.code-coupon .code-des {
  font-size: 12px;
  color: #aaa;
  -webkit-transform: scale(.9);
          transform: scale(.9);
}


.task-detail .campaign-duration{
   font-size: 13px;
   margin-bottom: 20px;
}
.campaign-duration .end-date {
  /* font-weight: 600; */
  font-size: 14px;
  color: #666;
}
.pending .task-st {
    width: 20px;
    height: 20px;

}
.pending .pend-txt {
  color: #f0774a;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 600;
}
.pending .task-close-txt {
   color:#666;
   margin-left: 0;
}

.campaigns-detail .status-des {
  font-size: 12px;
  color: #a1a1a1;
  margin-top: 23px;
}

.campaigns-detail .status-reject-reason{
  font-size: 12px;
  background-color : #FAEFE5;
  border: 1px solid #f6ded0;
  color: #a27758;
  margin-left: 35px;
  padding: 5px;  
  position: relative;
  margin-top: 3px;

}
.status-reject-reason .reason-arrow{
      left: 5px;
      position: absolute;
      width: 9px;
      height: 11px;
      top: -4px;
      margin-top: -6.5px;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
}
.status-reject-reason .ic-status-z {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.campaigns-detail .campaign-step {
   border: 1px solid #ddd;
   border-radius: 8px;
   margin-bottom: 20px;
   padding: 12px 10px 12px 10px; 
}

.step-i {
  display: flex;
}

.campaigns-detail .left {
  font-size: 20px;
  color: #f0774a;
  font-weight: bolder;
  white-space: nowrap;
  font-style: italic;
}




.task-detail .detail-info{
  padding: 0px 30px 0px 30px;
}

.task-detail .detail-info .title {
  margin-bottom: 20px;
}

.task-detail .duration-time{
   margin-left: 13px;
   color: #666;
} 

.task-reward .task-reward-amount {
  color: #F0774A;
  font-size: 16px;
  font-weight: bold;
}

.task-reward .end-date {
  font-size: 16px;
}



 .img-evidence .evidence-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0 20px 20px 0;
  width: 170px;
  height: 170px;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 8px;
}

.campaign-task-step .img-evidence .ant-upload.ant-upload-select{
   margin-top: 20px;
   margin-left: 10px;
}

.evidence-item-a {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* display: inline-block; */
  /* line-height: 100px; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.uploaded-s {
  position: absolute;
  bottom: 10px;
  color: #52c41a;
}

.evidence-item-img {
  width: 100%;
  display: inline-block;
}

.evidence-item-img-video {
  width: 85px;
  height: 64px;
}

.del-icon {
  position: absolute;
  right: -5px;
  top: -5px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 20px;
  cursor: pointer;
  display: none;
  z-index: 99;
}

.evidence-item:hover .del-icon {
  display: block;
}

.upload-evidence .ant-upload-list {
  display: none;
}

.upload-style {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  background-color: rgb(235, 235, 235);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}

.upload-style .add-icon {
  font-size: 30px;
}

.upload-style .text {
  font-size: 14px;
}

.link-intr {
  font-size: 13px;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 15px;
}
.zy-des {
  position: absolute;
  bottom: -35px;
  left: 0;
  display: flex;
  font-size: 12px;
  width: 200px;
}

.zy-des .zy-des-s{
  font-size: 12px;
  -webkit-transform: scale(.9);
          transform: scale(.9);
  margin-left: -10px;
}

.zy-des-link {
  position: unset;
  margin-top: 30px;
}
.zy-des .ic-zy {
  width: 15px;
  height: 15px;
  margin-right: 6px;
}
.up-des{
  font-size: 12px;
  -webkit-transform: scale(.9);
          transform: scale(.9);
}

.link-cont {
  position: relative;
  padding-right: 40px;
  margin-bottom: 10px;
  width: 280px;
  height: 34px;
}

.link-input {
  font-size: 12px;
  padding: 0px 11px;
  height: 33px;
  width: 300px;
}

.del-input {
  position: absolute;
  right: 5px;
  top: 50%;
  /* transform: translateY(-50%); */
  margin-top: -9px;
  display: none;
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.link-cont:hover .del-input {
  display: block;
}

.add-uri-btn {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.04) 0px 2px 2px 0px, rgba(0, 0, 0, 0.02) 0px 1px 5px 0px;
    border: 1px solid #eee;
  background-color: #fff;
  color: #f0774a;
  font-size: 13px;
  display: inline-block;
  border-radius: 4px;
  padding: 6px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.handle-submit {
  font-size: 14px;
  color: #fff;
  background: linear-gradient(to right, #F79C37, #F0774A);
  /* background-color: #F0774A; */
  padding: 7px 30px;
  text-align: center;
  border-radius: 10px;
  margin: 10px 0 0px 0;
  max-width: 240px;
  font-weight: 500;

}

.task-detail .detail-con {
  background-color: #fff;
}

.add-form .address-save-btn {
  width: 100%;
  background: linear-gradient(to right, #F79C37, #F0774A);
  border-radius: 5px;
  color: #fff;
  border:none;
}
.add-form .address-save-btn:hover{
  width: 100%;
  background: linear-gradient(to right, #F79C37, #F0774A);
  border-radius: 5px;
  color: #fff;
  border:none;
}

.add-form .ant-form-item-label{
  text-align: left;
}

.request-received {
  color: #f0774a;
  font-weight: bold;
}
.post-example-wrapper {
  margin-left:83px;
  padding-top: 10px;
}
.post-s-t {
  margin-top: 10px;
  font-size: 13px;
  font-weight: 600;
}

.post-guidelines {
    font-size: 13px; 
    color: #666;
}

.post-ex{
  flex-wrap: wrap;
  align-items: center;
}
.post-example{
  max-width: 120px;
  min-width: 120px;
  margin-right: 20px;
  margin-top: 10px;
  cursor: pointer;
}
.view-more-txt {
  color: #f0774a;
  cursor: pointer;
  margin-top: 10px;
  font-size: 16px;
}
.more-icon {
  width: 20px;
  margin-left: 5px;
  vertical-align: sub;
}


.sample-order {
  display: flex;
  margin-top:15px;
  margin-bottom:15px;
  cursor: pointer;

}

.sample-order .product-image {
  width:61px;
  
}
.s-p-order {
  flex: 1 1;
  padding-left: 18px;
  display: flex;
  /* align-items: center; */
  flex-direction: column;  
}
.sample-p {
  margin-bottom:6px;
  color:#999;
  font-size:13px;
}

.s-p-order-info {
  display: flex;
}

.sam-order-status {
  white-space: nowrap;
}
.sample-s-2 {
  display: flex;
  font-size:12px;
}
.sample-span {
  width:60px;
  display:inline-block;
}
.status-span {
  width:60px;
  display:inline-block;
  color: #666;
  min-width: 60px;


}
.track-url {
  text-decoration: underline;
  margin-top: 4px;
  display: block;

}
.error-sp {
  color:#ff4d4f;
  margin-top:5px;
  font-size:12px;
}

.my-sample-app {
  font-weight: 600;
  margin-bottom:5px;
}



@media only screen and (max-width: 640px) {
  .campaigns-detail .campaign-step {
    padding: 10px 10px 10px 10px;
  }

  .task-detail .box-wrapper{
    flex-direction: column;
    padding: 40px 15px 5px 15px;
  }
  

  .task-detail .detail-info{
    padding: 10px 5px 5px 0px;
  }
  .task-detail .box-wrapper{
    margin-bottom: 10px;
  }
  .task-detail  .pending{
    margin-top: 20px;
  }
  .campaigns-detail .status-reject-reason{
    padding: 2px 10px 2px 5px;
    -webkit-transform: scale(.9);
            transform: scale(.9);
  }

  .campaigns-detail .left {
    min-width: 50px;
    font-size: 19px;
  }

  .campaigns-detail .right {

  }

  .code-coupon .code-num{
    font-size: 16px;
  }
  .step-1 .content-de{
    font-size: 14px;
    margin-top: 0;
    /* white-space: nowrap; */
    overflow: hidden;
  }

  .right .content-how {
    flex-direction: column;
    margin-top: 10px;
  }

  .right .apply-btn-task {
    padding: 4px 20px;
    line-height: 30px;
  }

  .right .how-to-use {
    margin-top: 5px;
  }

  .right .img-evidence {
    width: unset;
  }
  .campaigns-detail .status-des{
    margin-top: 10px;
  }
  .task-detail .box-wrapper-c{
    box-shadow: none;
    border: none;
    padding: 7px;
  }
  .campaign-task-step{
    border: none;
  }
  .task-detail .step-i{
    flex-direction: column;
    align-items: end;
    justify-content: center;
  }
  .task-detail .post-ex {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .step-i .mobile-s-w{
    display: flex;
    
  }

  .campaign-task-step{
    padding: 5px 5px 5px 5px;
  }
  .campaign-task-step .center{
    position: inherit;
    margin-left: 0;
    width: 280px;
  }
  .code-coupon{
    width: 208px;
    /* height: 76px; */
  }
  .link-intr{
    margin-top: 20px;
  }

  .add-form .ant-form-item{
     margin-bottom: 0px;
  }
  .add-form .ant-form-item .ant-form-item-label{
    padding-bottom: 0;
  }
  .post-example-wrapper{
    margin-left: unset;
    padding-top: 5px;
  }
  .task-detail .box-wrapper{
    box-shadow: unset;
    border: unset;
  }
  .view-more-txt {
    text-align: left;
    color: #f0774a;
  }
  .apply-btn-task {
    display: inline-block;
    padding: .2rem .3rem;
    font-size: .2rem;
    min-width: 3rem;
  }
}
html,
body {
    position: relative;
    height: 100%;
}

body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}

body {
    background: #000;
}
.black-mask {
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   z-index: 9999999999;
   background: #000;

}

.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #000;
}

.swiper-slide img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
}
.black-mask .close-swiper {
    color: #fff;
    font-size: 26px;
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 999;
    cursor: pointer;
}

.black-mask .page-index {
    z-index: 999;
    color: #fff;
    font-size: 20px;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 20px;

}

.black-mask .download-pic {
    color: #fff;
    z-index: 999;
    font-size: 25px;
    position: absolute;
    top: 20px;
    left: 40px;
}


@media only screen and (max-width: 640px) {
    .black-mask .close-swiper {
        color: #fff;
        font-size: 24px;
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 999;
        cursor: pointer;
    }
    .black-mask .download-pic {
        color: #fff;
        z-index: 999;
        font-size: 25px;
        position: absolute;
        top: 20px;
        left: 20px;
    }

}
/* Make clicks pass-through */
#web-progress {
  pointer-events: none;
}

#web-progress .bar {
  background: #f0774a;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#web-progress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #f0774a, 0 0 5px #f0774a;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#web-progress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: calc(50% - 9px);
}

#web-progress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #f0774a;
  border-left-color: #f0774a;
  border-radius: 50%;
  display: none;

  -webkit-animation: web-progress-spinner 400ms linear infinite;
  animation: web-progress-spinner 400ms linear infinite;
}

.web-progress-custom-parent {
  overflow: hidden;
  position: relative;
}

.web-progress-custom-parent #web-progress .spinner,
.web-progress-custom-parent #web-progress .bar {
  position: absolute;
}

@-webkit-keyframes web-progress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes web-progress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@charset "utf-8";

/*!
 * @名称：base.css
 * @功能：1、重设浏览器默认样式
 *       2、设置通用原子类
 */
/* 防止用户自定义背景颜色对网页的影响，添加让用户可以自定义字体 */
html {
    color: #4d4d4d;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.44em;
}

/* 内外边距通常让各个浏览器样式的表现位置不同 */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    margin: 0;
    padding: 0;
}

/* 要注意表单元素并不继承父级 font 的问题 */
body,
button,
input,
select,
textarea {
    font: 14px \5b8b\4f53;
}

input,
select,
textarea {
    font-size: 100%;
}

/* 去掉 table cell 的边距并让其边重合 */
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* ie bug：th 不继承 text-align */
th {
    text-align: inherit;
}

/* 去除默认边框 */
fieldset,
img {
    border: none;
}

/* ie6 7 8(q) bug 显示为行内表现 */
iframe {
    display: block;
}

/* 去掉 firefox 下此元素的边框 */
abbr,
acronym {
    border: none;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
}

/* 一致的 del 样式 */
del {
    text-decoration: line-through;
}

address,
caption,
cite,
code,
dfn,
em,
th,
var {
    font-style: normal;
    font-weight: 500;
}

/* 去掉列表前的标识，li 会继承 */
ol,
ul {
    list-style: none;
}

/* 对齐是排版最重要的因素，别让什么都居中 */
caption,
th {
    text-align: left;
}

/* 来自yahoo，让标题都自定义，适应多个系统应用 */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: 500;
}

h1 {
    font-size: 26px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 20px;
}

q:before,
q:after {
    content: '';
}

/* 统一上标和下标 */
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

a:hover {
    text-decoration: underline;
}

/* 默认不显示下划线，保持页面简洁 */
ins,
a {
    text-decoration: none;
}

/* 去除 ie6 & ie7 焦点点状线 */
a:focus,
*:focus {
    outline: none;
}

/* 清除浮动 */
.clearfix:before,
.clearfix:after {
    content: '';
    display: block;
}

.clearfix:after {
    clear: both;
    overflow: hidden;
}

.clearfix {
    zoom: 1;
    /* for ie6 & ie7 */
}

.clear {
    clear: both;
    display: block;
    font-size: 0;
    height: 0;
    line-height: 0;
    overflow: hidden;
}

/* 设置显示和隐藏，通常用来与 js 配合 */
.hide {
    display: none;
}

.block {
    display: block;
}

/* 设置浮动，减少浮动带来的 bug */
.fl,
.fr {
    display: inline;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.mr15 {
    margin-right: 15px;
}

.flex {
    display: flex;
}

.pointer {
    cursor: pointer;
}

.mr15 .info {}

.info .user {
    line-height: 38px;
}

.info .grade {
    line-height: 12px;
    display: flex;
    white-space: nowrap;
}

.grade .ant-progress-line {
    margin-top: -6px;
}

.mission-handle .ant-input-number::after {
    content: '%';
    position: absolute;
    right: -25px;
    width: 10px;
    height: 10px;
    top: 50%;
    margin-top: -7px;
}

.num-precent .ant-input-number::after {
    content: '%';
    position: absolute;
    right: -25px;
    width: 10px;
    height: 10px;
    top: 50%;
    margin-top: -7px;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
    /* width: 5px; */
    background-color: #fff;
    /* height: 1px; */
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccc;
}
.loading-modal .ant-modal-content {
    border-radius: 5px;
}

.loading-modal .ant-modal-body {
    padding: 0px 10px 10px 10px;
}


.language-wrapper-m {
    /* margin-top: .3rem; */
    background-color: #fff;
}

.language-item-m .flag-icon {
    width: 20px;
}

.language-item-m .flag-name {
    margin-left: 17px;
    font-size: 13px;
}

.language-item-m {
    border-bottom: 1px solid #f4f4f4;
    padding: .2rem 0 .2rem .3rem;
    position: relative;
}

.select-t-m {
    color: #000;
    font-weight: 600;
    font-size: 16px;
}

.select-l-icon-m {
    width: 20px;
    float: right;
    margin-right: 10px;

}

@media only screen and (max-width: 640px) {

    .language-item-m {
        border-bottom: 1px solid #f4f4f4;
        padding: .28rem 0 .28rem .4rem;
        position: relative;
    }

}
/* @font-face {
  font-family: 'RobotoLight';
  src: url('./assets/font/Roboto-Light.ttf');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoNormal';
  src: url('./assets/font/Roboto-Regular.ttf');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoBold';
  src: url('./assets/font/Roboto-Bold.ttf');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
} */
 @font-face {
   font-family: 'Robot';
   src: url(/static/media/Roboto-Light.88823c20.ttf) format("truetype");
   font-weight: 200;
 }

 @font-face {
   font-family: 'Robot';
   src: url(/static/media/Roboto-Regular.11eabca2.ttf) format("truetype");
   font-weight: 500;
 }

 @font-face {
   font-family: 'Robot';
   src: url(/static/media/Roboto-Medium.58aef543.ttf) format("truetype");
   font-weight: 600;
 }

 @font-face {
   font-family: 'Robot';
   src: url(/static/media/Roboto-Bold.e07df86c.ttf) format("truetype");
   font-weight: 800;
 }

   @font-face {
    font-family: 'Robot';
    src: url(/static/media/Roboto-BoldItalic.5b44818d.ttf) format("truetype");
    font-weight: bold;
    font-style: italic;
   }





html,body {
  margin: 0;
  padding: 0;
  font-family: 'Robot'!important;
  /* font-size: 1rem; */
  line-height: 1.5;
  background-color: #fff;
}

/*
 ar css
 **
 */

.ar-AR {
  direction: rtl;
}

.ar-AR .campaigns-item .item-center {
  padding: 8px 24px 8px 0;
}
.ar-AR .campaigns-item .like-it {
  border-radius: 50px;
  font-size: 13px;
  color: #999;
  display: inline-block;
  padding: 1px 10px;
  text-align: center;
  cursor: pointer;
  bottom: 28px;
  left: 557px;
  white-space: nowrap;
  position: absolute;
  right: unset;
}
.ar-AR .contact-us .select-container .language-icon {
  margin-right: unset;
  margin-left: 7px;
}
.ar-AR .contact-us .select-container .selectTri {
  margin-right: 7px;
  margin-left: unset;
}
.ar-AR .login-password-container .ant-input-affix-wrapper {
  padding: 4px 0 4px 11px;
}
.ar-AR .login-password-container .ant-input-prefix {
  margin-left: 11px;
  margin-right: unset;
}
.ar-AR .email .code-btn {
  right: unset;
  left: 0;
}
.ar-AR .email .code-btn {
  border-right: 1px solid #ccc;
  border-left: unset;
}
.ar-AR .email .code-btn-disable {
  right: unset;
  left: 0;
  border-right: 1px solid #ccc;
  border-left: unset;

}


.ar-AR .join-now {
  left: -18px;
  right: unset;
}
.ar-AR .like-it .anticon-heart {
  margin-left: 7px;
}

.ar-AR .campaigns-detail .campaign-id{
  display: flex;
}
.ar-AR  .task-detail-new  .detail-info {
  padding: 0 25px 40px 50px;
}
.ar-AR .campaign-task-step {
  padding: 22px 26px 22px 90px;
}
.ar-AR .campaign-type {
  display: flex;
}
.ar-AR .campaign-id-con {
  margin-right: 10px;
}
.ar-AR .campaign-duration {
  display: flex;
}
.ar-AR .campaign-duration .dura-txt {
  margin-left: 10px;
  margin-right: unset;
}
.ar-AR .campaigns-item .average-earn .rewards-aver {
  margin-right: 15px;
  margin-left: unset;
}
.ar-AR .title-h {
  padding-right: 10px;
  padding-left: unset;
}
.ar-AR .title-h::after {
  right: -3px;
  left: unset;
}
.ar-AR .pending .pend-txt {
  margin-right: 10px;
  margin-left: unset;
}
.ar-AR .duration-time {
  margin-right: 12px;
}
.ar-AR .country-item {
  padding-right: 20px;
}
.ar-AR .tag-item {
  padding: 1px 7px 1px 0;
}
.ar-AR .free-sample {
  display: flex;
}
.ar-AR .ic-sample-icon {
  margin-top: 0;
}
.ar-AR .average-earn {
  display: flex;
  align-items: center;
}
.ar-AR .earn-money {
  margin-right: 10px;
}
.ar-AR .box-wrapper .like-it {
  right: 49px;
  left: unset;
}
.ar-AR .all-campaigns .goto-detail {
  left: -20px;
  right: unset;
}
.ar-AR .all-campaigns .goto-detail-verified {
  left: -20px;
  right: unset;
}
.ar-AR .campaigns-detail .s-p-order {
  padding-right: 18px;
}
.ar-AR .sample-apply-btn {
  margin-left: auto!important;
  margin-right: 0;
}
.ar-AR .task-deadline {
  margin-right: 85px;
}
.ar-AR .post-example-wrapper {
  margin-right: 83px;
}
.ar-AR .task-detail-new .campaign-task-step .center {
  right: 110px;
  margin-right: 30px;
}
.ar-AR .profile-layout-l{
  margin-right: 220px;
  margin-left: 0;
}
.ar-AR .profile-layout-l>.ant-layout {
  margin-right: 20px;
}
.ar-AR .right-info .info-title {
  text-align: right;
}
.ar-AR .profile-nav {
  margin-right: 0;
  margin-left: 14px;
}

.ar-AR .menu-w-i .arrow-down {
  left: 32px;
  right: unset;
}
.ar-AR .profile-nav .ar-nav-item {
  display: flex;
  align-items: center;
  padding: 0 34px 0 24px !important;
}
.ar-AR .profile-nav .ant-menu-submenu-title {
  display: flex;
  padding: 0 34px 0 24px!important;
}
.ar-AR .profile-nav .my-account-img {
  margin-right: 0;
  margin-left: 16px;
}
.ar-AR .profile-nav .my-earning-img {
  margin-right: 0;
  margin-left: 16px;
}
.ar-AR .profile-nav .ant-menu-sub >.ant-menu-item {
  padding-left: unset;
  padding-right: 63px!important;
  text-align: right;
}
.ar-AR .war-title {
  border-right: 2px solid #f0774a;
  border-left: unset;
}
.ar-AR .edit-txt {
  margin-right: 7px;
  margin-left: unset;
}

.ar-AR .personal-info .ant-form-item-label {
  display: flex;
  margin-left: unset;
  margin-right: 40px;
}
.ar-AR .edit-password .edit-password-txt {
  margin-right: 6px;
  margin-left: unset;
}
.ar-AR .address-operation img {
  margin-left: 5px;
  margin-right: 20px;
}
.ar-AR .address-item {
  padding: 30px 30px 0 0;
}
.ar-AR .bank-account .now-card-paypal {
  padding: 30px 30px 10px 20px;
}
.ar-AR .card-info .card-cont {
  margin-right: 20px;
}
.ar-AR .del-edit {
  left: 70px;
  right: unset;
}
.ar-AR .del-wrapper {
  margin-left: 60px;
}
.ar-AR .del-wrapper img,
.ar-AR .edit-wrapper img {
  margin-left: 10px;
  margin-right: 0;
}
.ar-AR .add-card-txt {
  margin-right: 10px;
}
.ar-AR .add-bank-account .ant-form-item-label {
  text-align: right;
}
.save-btn-submit .ant-form-item-control-input-content {
  text-align: center;
}
.ar-AR .data-preview .data-info {
  margin-right: 10px;
  margin-left: unset;
}
.ar-AR .data-info-total {
  margin-right: 10px;
  margin-left: unset;
}
.ar-AR .history-list .status-txt {
  margin-left: unset;
  margin-right: 6px;
}
.ar-AR .my-sample .campaigns-item .item-center {
  padding: 29px 24px 29px 0px;
}
.ar-AR .my-sample .ic_sample {
  margin-left: 12px;
  margin-right: unset;
}
.ar-AR .my-sample .sample-status-value {
  margin-right: 10px;
  margin-left: unset;
}

.ar-AR .campaigns-detail .box-wrapper {
  padding: 32px 25px 100px 32px;
}
.ar-AR .campaigns-detail .detail-info {
  padding: 0 25px 40px 50px;
}





.ar-AR .my-profile-mobile .item-go {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ar-AR .my-profile-mobile .menu-item {
  padding: .4rem .4rem .4rem .2rem;
}
.ar-AR .my-profile-mobile .menu-item .menu-icon {
   margin-left: .3rem;
   margin-right: 0; 
}
.ar-AR .my-profile-info-mobile .form-val {
  margin-right: 0;
  margin-left: .2rem;
}


.ar-AR .change-language .language-item {
  padding: .28rem .4rem .28rem 0;
}
.ar-AR .change-language .select-l-icon {
  left: .4rem;
  right: unset;
}

.ar-AR .contact-us .body-download {
  margin-left: 8px;
}

.ar-AR .campaigns-detail-mobile .box-wrapper .like-it {
  left: 27px;
  right: unset;
}
.ar-AR .campaigns-detail-mobile .title-h::after {
  right: -3px;
  left: unset;
}
.ar-AR .campaigns-detail-mobile .step-item {
  padding: 10px 15px 10px 6px;
}
.ar-AR .campaigns-detail-mobile .step-name {
  padding-right: 10px;
  padding-left: unset;
}
.ar-AR .campaigns-detail-mobile .step-item img {
  margin-left: 10px;
  margin-right: unset;
}
.ar-AR .campaigns-detail-mobile .step-item .step-des {
  text-align: right;
}
.ar-AR .campaigns-detail-mobile .title-h {
  padding-right: 10px;
  padding-left: unset;
}
.ar-AR .campaigns-detail-mobile .des-detail ul {
  padding-right: .3rem;
  padding-left: unset;
}
.ar-AR .link-cont {
  padding-right: unset;
}
.ar-AR .task-progress .progress-img {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.ar-AR .task-progress-wrapper {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ar-AR .task-progress-item-icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ar-AR .register .agree {
  margin-right: -23px;
}
.ar-AR .agree .reg-check-icon {
  margin-left: 10px;
  margin-right: unset;
}




.ar-AR .ant-modal-close {
  left: 0;
  right: unset;
}
.ar-AR .sample-content .sample-se-name {
  margin-left: unset;
  margin-right: 8px;
}
.ar-AR .follow-rule .rule-item {
  margin-right: 10px;
  margin-left: unset;
}
.ar-AR .sample-apply-modal .rule-content {
  margin-right: 15px;
  margin-left: unset;
}

.ar-AR .am-modal-wrap .close-icon {
  left: 10px;
  right: unset;
}


.ar-AR .ic-in-progress {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ar-AR .ant-progress-line {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ar-AR .card-num-n {
  margin-right: 10px;
  margin-left: unset;
}
.ar-AR .gray-icon {
  margin-right: -4px;
  margin-top: 4px;
}


.ar-AR .how-earn-rule .how-txt1 {
   text-align: right;
   padding-right: 15px;
}
.ar-AR .how-earn-rule .how-txt1::before {
  right: 0;
  left: unset;
}

.ar-AR .how-to-pati .ant-timeline-item-head-custom {
  right: -34px;
  left: unset;
}
.ar-AR .how-to-pati .ant-timeline-item-tail {
  right: 3px;
  left: unset;
}
.ar-AR .ant-timeline-item-content {
  margin: 0 26px 0 0 ;
}


button:disabled {
  opacity: 0.5;
}

::-webkit-scrollbar {
  height: 0px;
  width: 1px;

}
div::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.ml-1{
  margin-left: 10px;
}
.ml-2{
  margin-left: 20px;
}
.link-item {
  color: #fff;
}

.point-class {
  cursor: pointer;
}

.normal-link {
  color: #000;
}
.opa1 {
  -webkit-animation: opacity1 1.5s ease-in-out;
          animation: opacity1 1.5s ease-in-out;
}
.theme-color {
  color: #f0774a;
}
.swiper-modal .ant-modal-body {
  padding: 0;
}
@-webkit-keyframes opacity1 {
   from {opacity: 0;}
   to {opacity: 1;}
}
@keyframes opacity1 {
   from {opacity: 0;}
   to {opacity: 1;}
}

.ant-input {
  border-radius:4px;
  padding-left: 6px;
}

.ant-btn {
  border-radius: 0;
}

.ant-back-top {
  bottom: 130px;
  z-index: 99999;
}

.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}

.ant-btn-primary {
  background: #f0774a;
  border-color: #f0774a;
}

.search-wrapper:hover {
  border: 1px solid #f0774a;
}

.search-w-focus {
  border: 1px solid #f0774a;
  box-shadow: 0 0 0 2px rgb(240 119 74 / 20%);
}

.load-more-wr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}

.load-more-wr .load-more-sp {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 12px 24px 12px 24px;
  background: linear-gradient(to right, #F79C37, #F0774A);
  border-radius: 100px;
  display: inline-block;
  cursor: pointer;
}
.ant-modal-mask,
.ant-image-preview-mask,
 .ant-image-preview-mask{
   background-color: rgba(240,240,240,.65);
 }

 .ant-notification{
   right: 50% !important;
   -webkit-transform: translateX(50%);
           transform: translateX(50%);
   margin-right: unset;
 }

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  /* background-color: #f9c9b7;
  border-color: #f9c9b7; */
  background: #f0774a;
  border-color: #f0774a;
}

.ant-input:hover {
  border-color: #f0774a;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #f0774a;
  box-shadow: 0 0 0 2px rgba(240, 119, 74, 0.2);
}

.ant-input-affix-wrapper:hover {
  border-color: #f0774a;
  z-index: unset;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #f0774a;
  box-shadow: 0 0 0 2px rgba(240, 119, 74, 0.2);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #f0774a;
}

.ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
  box-shadow: 0 0 0 2px rgba(240, 119, 74, 0.2);
}

.ant-radio-checked .ant-radio-inner {
  border-color: #f0774a;
}

.ant-radio-checked::after {
  border: 1px solid #f0774a;
}

.ant-radio-inner::after {
  background-color: #f0774a;
}

.account-form .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f0774a;
  border-color: #f0774a;
  border-radius: 100px;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #f0774a;
}

.ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
  border-color: #f0774a;
}
.ant-menu-sub.ant-menu-inline{
  background: #fff;
}

.ant-menu-submenu-selected{
  color: #000;
}
.ant-menu-item-selected{
  color: #f0774a;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: #fff;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover{
  color: #f0774a;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after{
  /* border: none; */
  border-color: #f0774a;
}
.ant-menu-submenu-vertical>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before{
  background:#f0774a;
}
.ant-menu{
  font-size: 12px;
}
a:active,
a:hover {
  color: #f0774a;
}

.ant-btn:hover,
.ant-btn:focus{
  background: linear-gradient(to right, #F79C37, #F0774A);
  color: #fff;
}

.ant-input-affix-wrapper:hover{
  /* border-color: #d9d9d9; */
  border-color: #f0774a;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused{
  /* border-color: #d9d9d9; */
  border-color: #f0774a;
}
/* .ant-pagination-item-active a {
  color: #f0774a;
}

.ant-pagination-item-active {
  border-color: #f0774a;
} */

.ant-form-item-label>label::after{
  content: '';
}
.ant-form-item-label>label{
  font-size: 13px;
}

.app .header {
  background-color: #fff;
  padding: 0;
  height: unset;
}
.header-white {
  box-shadow: 0px 2px 13px 0px rgba(228, 228, 228, 0.6);
}
.header-black .contact-us {
  background-color: rgb(30,26,27);
  color: #fff;
  
}

.header-white .contact-us {
  background-color: #fff;
}

.contact-us .contact-body {
  max-width: 1200px;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 300;
  display: flex;
  justify-content: flex-end;
  height: 38px;
  line-height: 38px;
}
.header-white-logo {
  height: 20px;
}


.contact-us .select-container {
  display: inline-block;
  padding: 0 18px;
  position: relative;
  cursor: pointer;
}

.header-white .contact-us .select-container{
  color: #666;
}
.header-white .app-header-new .select-container {
  height: 41.3px;
  line-height: 41.3px;
}

.contact-us .select-container span {
   font-size: 14px;
   font-weight: 400;
}
.contact-us .select-container img {
  width: 14px;
  margin-left: 4px;
}
.contact-us .select-container .language-icon {
  vertical-align: inherit;
  margin-right: 7px;
}
.contact-us .select-container .selectTri {
  /* transform: translateY(-44%); */
  margin-left: 7px;
  vertical-align: revert;
}
.contact-us .select-container:hover .selectTri {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition: all .2s;
}
.contact-us .contact-body .select-container .selectTri-s {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition: all .2s;
}
.contact-us .contact-body .select-container .selectTri-h {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: all .2s;
}

.contact-us .select-container:hover .selected-language{
  /* color: #fff; */
}
.contact-us .select-container .language-menu {
  /* width: 137px; */
  min-width: 180px;
  /* background-color: #fff; */
  background-color: #1e1a1b;
  position: absolute;
  /* color: #333; */
  color: #fff;
  top: 50px;
  right: 16px;
  z-index: 9999;
  box-shadow: 0 12px 60px rgb(14 32 66 / 15%),
  0 1px 2px rgb(14 32 66 / 5%);
  /* padding: 5px 10px 5px 10px; */
  opacity: 0;
  transition: all .5s;
  height: 0px;
  overflow: hidden;
}
.app-header-new .select-container .language-menu {
  right: -10px;
}

.header-white .select-container .language-menu{
  background-color: #fff;
  color: #666;
}

.header-white .contact-us .language-menu .language-select-item {
  color: #666;
}

.contact-us .select-container:hover .language-menu{
  opacity: 1;
  /* margin-top: 10px; */
  top: 38px;
  height: unset;
}

.app-header-new .select-container:hover .language-menu{
  opacity: 1;
  top: 42px;
  right: -10px;
  left: unset;
}
.login-password-container .email-code-wraper .ant-btn:hover,
.login-password-container .email-code-wraper .ant-btn:focus {
  background: none;
}


.contact-us .contact-body .select-container .language-menu-show {
   opacity: 1;
   height: unset;
}

.contact-us .contact-body .select-container .language-menu-hidden {
  height: 0px;
  opacity: 0;
}
.contact-us .con-header-body .select-container .language-menu-hidden {
  height: 0px;
  opacity: 0;
}


.language-menu .language-select-item{
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 38px;
  line-height: 34px;
  position: relative;
}

.language-select-item .flag-icon{
  position: absolute;
  left: 4px;
}

.select-container .language-menu .language-select-item:hover {
  color: #f79c37;
  /* background-color: #fbf2ee; */
  background: rgba(255, 255, 255, 0.1);
}
.header-white .contact-us .language-menu .language-active {
  color: #f79c37;
  /* background-color: #fbf2ee; */
  background: rgba(255, 255, 255, 0.1);
}
.header-black .contact-us .language-menu .language-active {
  color: #f79c37;
  /* background-color: #fbf2ee; */
  background: rgba(255, 255, 255, 0.1);
}

.contact-us .body-download {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: #fff;
  margin-right: 8px;
}
.contact-us .body-download .open-app-btn {
  background: linear-gradient(to right, #F79C37, #F0774A);
  padding: .1rem .2rem; 
  border-radius: 3px;
  font-size: .24rem;
}

.contact-email {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
}

.con-login-singup{
  display: none;
}

.contact-email .contact-e {
  width: 17px;
  margin-right: 8px;
}
.contact-email .contact-txt{
  -webkit-transform: scale(.93);
          transform: scale(.93);
}

.contact-us .select-container select {
  border: none;
  background-color: rgb(234,232,232);
  position: auto;
  height: 100%;
  padding: 8px 35px;
  appearance: none;
  -moz-appearance: none;
  text-align: center;
  -webkit-appearance: none;
  -webkit-transform: scale(.93);
          transform: scale(.93);
}

.header-black .select-container select {
  /* background-color: rgb(64, 60, 61); */
}



.contact-us .anticon-mail {
  vertical-align: middle;
  margin-right: 6px;
  margin-top: -2px;
}

.header .header-account {
  max-width: 1000px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  padding: 15px 18px 15px 18px;
}

.header-account .logo-img {
  width: 170px;
}

.header-account .logo-img img {
  width: 100%;
}

.user-header .my-account {
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-account .header-arrow {
  display: none;
}
.my-account .nav-header-d{
  display: flex;
  align-items: center;
}


.my-account .header-img {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  border-radius: 50%;
  border: 1px solid #eee;
}
.my-account .account-email{
  font-size: 12px; 
  -webkit-transform: scale(.94); 
          transform: scale(.94);
}
.my-account .ant-dropdown-link{
  color: #f0774a;
}

.main-logo {
  height: 40px;
  margin-right: 40px;
  cursor: pointer;
}

.nav-bar {
  /* background-color: rgb(249, 249, 249); */
  box-shadow: 0px 0px 13.5px rgba(0, 0, 0, .1);
  background-color: #fff;
}

.nav-bar .nav-con {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-con .nav-menu {
  display: flex;
  align-items: center;
  z-index: 999;
  background-color: #fff;

}

.nav-con .nav-item {
  color: #000;
  width: 150px;
  padding: 23px 0;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
  font-weight: 600;
  position: relative;
  white-space: nowrap;
}
.nav-con .nav-item .nav-link{
  font-size: 17px;
  transition: color .3s;
}

.nav-con .nav-item .nav-link:hover{
  color: #f0774a;
}

/**
  * hover highlight
 **/ 
.high-light-text {
   transition: color .3s;
} 
.high-light-text:hover{
   color: #f0774a!important;
}


.login-signup {
   cursor: pointer;
}
.login-signup .login-s {
  color: #f0774a;
  font-size: 14px;
  font-weight: bold;
}
.login-signup .gray-line {
  margin: 0 10px;
  height: 20px;
  display: inline-block;
  border-right: 1.5px solid #999;
  vertical-align: middle;
}
.login-signup .signup-s {
  color: #000;
  font-size: 14px;
  font-weight: bold;
}




.nav-con .nav-item-active {
  color: #f0774a;
  position: relative;
}
.nav-con .nav-item-active .ant-badge{
  color: #f0774a;
}
/* #messageDot::after{
  content: '';
  width: 6px;
  height: 6px;
  background-color: #f0774a;
  border-radius: 50%;
  display: inline-block;
  top: 20px;
  position: absolute;
  right: 22px;
} */


.loading {
  text-align: center;
  color: #f0774a;
}

.loading .ant-spin-dot-item {
  background-color: #f0774a;
}
.img-evidence .ant-spin {
  color: #f0774a;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 999;
}
.ant-select-item{
  font-size: 13px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(240, 119, 74, .2)
}

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
 
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-color: #f0774a;
  box-shadow: 0 0 0 2px rgba(240, 119, 74, .2)
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #f0774a;
}

.hide {
  display: none;
}

.ant-layout{
  background-color: #fff;

}




.app-footer {
  z-index: 999;
  
}
.app-footer .footer-gray {
  /* background-color: rgb(245,242,240); */
  background-color: #111114;
  position: relative;
  bottom: -10px;
}
.app-footer .footer-black {
  background-color: #111114;
  text-align: center;
  color: #fff;
  padding: 20px 0 10px 0;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-gray .footer-g-content {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px 0;
}
.footer-link-2 {
  margin-left: 40px;
}
.footer-g-content .cont-left {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cont-left .logo-ab {
  height: 18px;
}
.cont-left .ic-sx {
  height: 30px;
  margin: 0 10px;
}
.cont-left .logo {
  height: 40px;
}
.cont-right {
  display: flex;
  align-items: center;
} 
.cont-right .follow-us {
  font-size: 14px;
  margin-right: 10px;
  color: #fff;
}
.linkedin-link, .ins-link {
  display: inherit;
  margin-right: 10px;
}
.cont-right .logo-fb {
   height: 35px;
   margin: 0 5px;
}
.cont-right .logo-google {
   height: 35px;
} 

.cont-right .ins-logo-footer {
   color: #707a8a;
   font-size: 17px;
}
.cont-right .linkin-logo-footer {
  color: #707a8a;
  font-size: 17px;
}
.cont-right .ins-logo-footer:hover {
   color: #fff; 
}
.cont-right .linkin-logo-footer:hover {
  color: #fff;
}




.input-warning {
  border-color: #f0774a;
}
.input-warning-txt {
  color: #ff4d4f;
  font-size: 12px;
  -webkit-transform: scale(.9);
          transform: scale(.9);
  margin-left: -10px;
}





.ant-checkbox-checked::after{
  border:1px solid #f0774a;
}
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #f0774a;
    border-color: #f0774a;
}



/**
  message nav
*/

.message-nav {
  /* width: 171px; */
  width: 220px;
  /* height: 540px; */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, .06);
  border: 1px solid #f4f4f4;
  margin-right: 10px;
  padding-top: 30px;
  max-height: 500px;
  border-radius: 8px;
  position: fixed;
}

.app-inative  .message-nav{
  top: 10px;
  position: fixed;
}

.message-menu-list {

}

.message-menu-item {
  margin-bottom: 30px;
  font-size: 14px;
  color: #666;
  cursor: pointer;
  font-weight: bolder;
  position: relative;
  display: flex;
  align-items: center;
}
.m-item-active{
  color: #f0774a;
  border-right: 2px solid #f0774a;
}

.message-menu-item .m-menu-name {
  padding: 4px 15px 4px 35px;
}
.message-menu-item .m-nums {
  background-color: #f0774a;
  color: #fff;
  border-radius: 100px;
  padding: 0px 11px;
  font-size: 12px;
  -webkit-transform: scale(.8);
          transform: scale(.8);
  height: 20px;
  line-height: 20px;
}

.message-menu-item:hover{
  font-weight: bolder;
  /* color: #f0774a; */
}



.region-select {
  color: #f0774a;
  font-size: 17px;
  margin-right: 5px;
  margin-top: -3px;
}

.sample-apply-modal .ant-modal-content{
  border-radius: 10px;
}

.sample-apply-modal  .sample-modal-header{
    font-size: 21px;
    font-weight: 600;
}
.sample-ins-wrapper {
  background-color: #F8F9FC;
  padding: 22px 17px 22px 17px;
  margin-top: 10px;
}
.sample-ins-wrapper .ins-connect-w {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ins-connect-w .ins-connect-btn{
  border-radius: 4px;
  border: 1px solid rgba(225, 224, 227, 1);
  font-size: 16px;
  padding: 0 8px;
  cursor: pointer;
}
.sample-ins-wrapper .ins-label {
    color: #1E191A;
    font-size: 17px;
    font-weight: 600;
}
.sample-ins-wrapper .ins-value {
  color: #1E191A;
  font-size: 15px;
  margin-top: 10px;
}
.sample-ins-wrapper .ins-input {
  margin-top: 7px;
}
.sample-selected-wrapper {
   margin-top: 16px;
}
.sample-selected-wrapper .sample-s-header{
  font-size: 18px;
  margin-bottom: 7px;
}
.sample-selected-wrapper .sample-content{
   display: flex;
   align-items: center;
}
.sample-content .sample-pic {
  width: 62px;
  height: 62px;
  object-fit: contain;
}
.sample-content .sample-se-name {
  font-size: 16px;
  color: #666;
  font-weight: 400;
  margin-left: 8px;
}
.sample-apply-modal .follow-rule .term {
  font-size: 18px;
  color: #1E191A;
}
.follow-rule .rule-item {
   margin-top: 18px;
   margin-left: 10px;
}
.sample-apply-modal .rule-index {
   background-color: #eee;
   border-radius: 50%;
   width: 20px;
   height: 20px;
   line-height: 20px;
   display: inline-block;
   text-align: center;
}
.sample-apply-modal .rule-content {
   font-size: 15px;
   margin-left: 15px;
}
.sample-apply-modal .check-term {
  text-align: center;
  padding: 30px 0 15px;
}
.sample-apply-modal .submit-button{
  width: 380px;
  margin: 0 auto;
}
.sample-apply-modal .submit-button .save-btn {
  height: 40px;
}


/**
 profile layout
*/
.profile-layout {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  /* position: relative; */
  /* top: 89px; */
  padding-top: 130px;
}
.profile-layout-l {
  margin-left: 220px;
}
.profile-layout-l >.ant-layout {
  margin-left: 10px;
}

.profile-nav {
  width: 220px;
  height: 660px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, .06);
  margin-right: 14px;
  border-radius: 12px;
  overflow: hidden;
  border-bottom: 1px solid #F4F0EE;
}
.profile-nav .ant-menu-submenu-title{
  margin-bottom: 14px;
  font-weight: 600;
  font-size: 14px;
  position: relative;
}

.profile-nav .ant-menu-item{
  font-size: 13px;
  padding-left: 63px!important;
}
.profile-nav .nav-my-earn{
  padding-left: 24px!important;
  font-size: 14px;
  font-weight: 600;
}

.profile-nav .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  font-weight: 600;
}

.profile-nav .ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after{
  height: 32px;
  margin-top: 4px;
}

.profile-nav .ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left{
  border-right: none;
}
.profile-nav .ant-menu-submenu-arrow {
  display: none;
}

.nav-header {
   background-image: url('https://cdn.firstlinkapp.com/upload/2020_10/1603261927396_80930.jpg');
   background-size: cover;
   position: relative;
   width: 100%;
   /* min-height: 171px; */
   height: 220px;

   margin-bottom: 75px;

}

.edit-header{
  position: absolute;
  border-radius: 50%;
  top: 10px;
  left: 10px;
}


.edit-header .edit-header-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.nav-h-name {
  background: url(/static/media/nav_h_name.9008e587.png) center no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
  height: 117px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
.n-h-name {
   font-size: 19px;
   font-weight: 600;
   margin-bottom: 7px;
}
.n-h-email {
  font-size: 13px;
  color: #666;
}
.my-account-img {
  width: 22px;
  margin-right: 16px;
}
.my-earning-img {
  width: 22px;
  margin-right: 16px;
  vertical-align: sub;
}

.menu-w-i .arrow-down {
  width: 13px;
  height: 6px;
  position: absolute;
  right: 32px;
  top: 17px;

}
.heade-n-arrow{
  width: 15px;
  height: 15px;
  margin-left: 4px;
  cursor: pointer;
}

.my-account-img-1 {
  width: 15px;
  margin-top: -4px;
  margin-right: 10px;
}

.logout-img {
  width: 15px;
  margin-top: -4px;
  margin-right: 10px;
}

.war-title{
  font-weight: 600;
  border-left: 2px solid #f0774a;
  padding: 0px 10px;
}

.fixed-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.app-inative .fixed-bar {
  opacity: 0;
  transition: .8s;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);

}

.ins-connect{
  background-color: rgb(251, 249, 250);
  text-align: right;
  padding: 5px 10px 5px 0;
}

.load-more-wr .ant-spin-dot-item {
  background-color: #fff;
}
.load-more-wr .loading-text {
  margin-left: 10px;
}


.default-layout-app{
  /* padding-top: 110px; */
}
.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon{
  color: #C6C6C6;
  display: none;
}

.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input-affix-wrapper-focused{
  /* box-shadow: none; */
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused{
  /* box-shadow: none; */
}
.ant-input:focus,
.ant-input-focused{
  /* box-shadow: none; */
  /* border-color: #d9d9d9; */
}
.ant-input:hover{
  /* border-color: #d9d9d9; */
}

.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix{
  padding-right: 0;
}
.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon{
  right: -35px;
}

.ant-dropdown{
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title{
  padding: 5px 55px 5px 20px;
}
.ant-dropdown-menu{
  border-radius: 6px;
  border: 1px solid #FAE8E1;
  padding: 10px 0;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover{
      /* background-color: #fff; */
      /* border-left: 2px solid #f0774a; */
      color: #f0774a;
}
.ant-dropdown{
   
}
.ant-dropdown-placement-bottomRight>.ant-dropdown-arrow{
      right: 16px;
      border: 1px solid #FAE8E1;
      z-index: -1;
}

.ant-message{
  top: 90px;
}
.ant-message-notice-content{
  background-color: #999;
  opacity: .7;
  color: #fff;
  border-radius: 8px;
  padding: 8px 16px;
}
.ant-message .anticon{
  display: none;
}

img{
  opacity: 1;
  transition: opacity .3s ease-in;
}


.ant-skeleton{
  margin-bottom: 10px;
}
.ant-skeleton-header{
  display: block;
  width: 360px!important;
  height: 200px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle{
  border-radius: 0!important;
  width: 100%;
  height: 100%;
}

.my-sample-mobile .ant-skeleton-header{
  width: unset!important;
  height: unset;
  display: table-cell;
}
.my-sample-mobile .ant-skeleton-content{
  display: table-cell;
}
.my-sample-mobile .ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle{
  width: 78px;
  height: 78px;
}


.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background-color: #FAE8E1;
}
.ant-cascader-picker:focus .ant-cascader-input {
  border-color:#f0774a;
  box-shadow:none;
}

.ant-back-top-content{
  color: rgba(238, 107, 25, .87);
  background: #fff;
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / 10%);
}
.ant-back-top-content:hover{
   opacity: 1;
   background: #fff;
}
.ant-back-top-content .ant-back-top-icon{
  font-size: 18px;
}

@media only screen and (max-width: 640px) {

  .ant-input{
    font-size: .3rem;
  }


  .sample-apply-modal .submit-button {
    width: 260px;
    margin: 0 auto;
  }


  .contact-us .contact-body{
    justify-content: space-between;
  }
  .contact-body .contact-email {
    margin-left: 15px;
  }
  .contact-body .select-container {
    margin-right: 10px;
    padding: 2px 6px 2px 6px;
  }
  .select-container select {
    border-radius: 100px;
  }

  .header-account .logo-img {
    width: 120px;
  }

  .header-account .my-account {
    flex-direction: column;
    justify-content: center;
  }

  .my-account .account-email {
     font-weight: 600;
     -webkit-transform: scale(.89);
             transform: scale(.89);
  }

  .profile-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    top: 80px;
    padding-top: 0;
  }
  .profile-layout-l {
    margin-left: unset;
  }
  .profile-layout-l>.ant-layout {
    margin-left: unset;
  }

  .messages-layout {
    top: 125px;
  }

  .nav-bar {
    box-shadow: 0 0 black;
  }

  .nav-bar .nav-con{
    flex-direction: column;
    align-items: end;
    display: flex;
    flex-direction: column-reverse;

  }
  .nav-con .nav-menu{
    width: 100%;
    position: relative;
    top: -15px;
    border-top-right-radius: 56px;
    box-shadow: 0px -6px 10px 0px rgba(30, 25, 26, .09);
  }
  .nav-con .nav-menu[isaccount="true"] {
    top: 0;
  }

  .nav-con .my-profile {
    width: 100%;
    margin-top: 10px;
  }


  .nav-con .nav-menu img {
    display: none;
  }

  .profile-nav{
     width: 100%;
     height: unset;
     border-bottom: 10px solid #f4f4f4;
     position: unset;

  }
  .profile-nav .ant-menu{
    
  }
  .profile-nav .ant-menu-root {
    display: flex;
    position: relative;
    padding-bottom: 9px;
    overflow: hidden;
  }
  .profile-nav .ant-menu-root::before{
    content: '';
    height: 20px;
    border-right: 1px solid #999;
    position: absolute;
    left: 50%;
    top: 15px;
  }
  .profile-nav .ant-menu-submenu{
    flex: 1 1;
  }
  .profile-nav .ant-menu-item{
    padding-left: 30px!important;
  }
  .profile-nav .ant-menu-submenu-title{
    padding-left: 0px!important;
    margin-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    text-align: center;

  }

  .profile-nav .menu-w-i .arrow-down{
    width: 10px;
    position: unset;
    margin-left: 4px;
  }

  .profile-nav .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after{
    border: none;
  }
  .profile-nav .ant-menu-sub.ant-menu-inline>.ant-menu-item,
  .ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title{
    margin-top: 0;
    margin-bottom: 0;
    height: 30px;
  }

  .profile-nav .my-account-img{
    width: 18px;
    margin-right: 14px;
  }
  .profile-nav .my-earning-img{
    width: 18px;
    margin-right: 14px;
  }

  .profile-nav .my-earn-nav .arrow-down{
    margin-left: 7px;
  }

  .nav-header{
        width: 100px;
        margin: 12px auto 0;
        height: 100px;
        position: relative;
        margin-bottom: 60px;
  }
  .edit-header{
    left: -100px;
  }
  .default-layout-app{
    /* padding-top: 140px; */
  }

  .message-nav{
    margin-right: unset;
    width: 100%;
    padding-top: 0;
    box-shadow: 0px -5px 8px 0px rgba(30, 25, 26, .09);
    border: none;
    border-top-left-radius: 27px;
    border-top-right-radius: 0;
    position: fixed !important;
    z-index: 999;
    background-color: #fff;
  }
  .app-inative .message-nav {
    top: 0px;
    position: fixed;
  }
  .message-menu-list{
    overflow: hidden;
    padding: 10px 14px 10px 14px;
    height: 40px;

  }
  .m-over {
    display: flex;
    overflow-x: scroll;
    padding-bottom: 10px;
    /* -webkit-overflow-scrolling: touch; */
  }
  .message-menu-list .m-item-active{
    border-right: none;
    position: relative;
    background-color: rgb(253,239,233);
    color: #f0774a;
    /* border-bottom: 2px solid #f0774a; */
  }
   /* .message-menu-list .m-item-active::after{
     width: 50px;
     height: 2px;
     content: '';
     display: block;
     background-color: #f0774a;
     position: absolute;
     left: 50%;
     transform: translateX(-50%);
     bottom: -4px;

   } */

   .message-menu-item:hover {
     /* color: #666; */
   }


  .user-header .my-account{
    padding: 3px 10px 17px 10px;
    background-color: #E7D4CC;
    position: relative;
    /* top: 20px; */
    margin: 0px 15px 0 15px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    justify-content: flex-start;

  }

  .my-account .header-arrow {
    width: 16px;
    position: absolute;
    right: 20px;
    display: block;
  }

  .my-account .header-img{
    width: 34px;
    height: 34px;
    border: 2px solid #F4EBE8;

  }

  .user-header .heade-n-arrow{
     display: none;
  }
  .message-menu-item{
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    flex: 1 1;
    justify-content: center;
     border-top-left-radius: 14px;
     border-top-right-radius: 20px;
     border-bottom-right-radius: 20px;
  }
  .message-menu-item .m-menu-name{
    font-size: 12px;
    white-space: nowrap;
    -webkit-transform: scale(.95);
            transform: scale(.95);
    padding: 6px 10px 6px 10px;
   

  }
  .nav-h-name{
     height: 30px;
     background: none;
     bottom: -55px;
     width: 200px;
     left: 50%;
     margin-left: -100px;
  }
  .nav-con .nav-item{
    font-size: 13px;
    padding: 15px 0 0 0;
    position: relative;
  }
  .nav-con .nav-item::before {
    content: '';
    display: block;
    height: 20px;
    border-right: 1px solid #eae6e4;
    position: absolute;
    bottom: 0;
  }
  .nav-con .nav-item[first="true"]::after {
   display: none;
  }


  .ant-skeleton{
    width: 80%;
    display: block;
    margin: 0 auto 20px;
  }
  .ant-skeleton-header{
    width: 100%!important;
  }
  .ant-skeleton-content{
    display: block;
  }
  .ant-form label{
    line-height: 2;
  }

  .contact-us .select-container select{
    padding: 6px 30px;
  }
  .contact-email{
    margin-right: 10px;
  }

  .nav-con .nav-item .nav-link {
    font-size: 14px;
  }





  .app-footer .footer-gray{
    padding: 0 10px 0 10px;
  }


  .load-more-wr{
    margin-top: 30px;
    margin-bottom: 30px;
  }



  .footer-gray .footer-g-content{
    width: 100%;
  }
  .cont-left .logo-ab {
    height: 14px;
  }

  .cont-left .ic-sx {
    height: 14px;
    margin: 0 5px;
  }

  .cont-left .logo {
    height: 20px;
  }
  .cont-right .follow-us {
    font-size: 12px;
  }
  .cont-right .logo-fb {
    height: 25px;
    margin: 0 5px;
  }

  .cont-right .logo-google {
    height: 25px;
  }




  .login-signup {
    cursor: pointer;
    margin: 10px 0 5px 5px;
  }

  .login-signup .login-s {
    color: #f0774a;
    font-size: 12px;
    font-weight: bold;
  }

  .login-signup .gray-line {
    margin: 0 10px;
    height: 17px;
    display: inline-block;
    border-right: 1.5px solid #999;
    vertical-align: middle;
  }

  .login-signup .signup-s {
    color: #000;
    font-size: 12px;
    font-weight: bold;
  }

  .con-login-singup{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 13px!important;
        cursor: pointer;
  }




  .message-menu-item .m-nums{
        min-width: 35px;
        padding: 0 6px;
        text-align: center;
        margin-left: -6px;
  }


  .contact-us .select-container .language-menu {
    left: 0;
  }

  .app-footer .footer-black{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px 10px 10px;
  }
  .footer-link-2 {
    margin-left: 0;
  }


  .ar-AR .my-account .header-arrow {
    left: 20px;
    right: unset;
    -webkit-transform:rotate(180deg);
            transform:rotate(180deg);
  }
  .ar-AR .profile-layout-l {
    margin-right: 0;
    margin-left: 0;
  }
  .ar-AR .profile-layout-l>.ant-layout {
    margin-right: unset;
  }
  .ar-AR .all-campaigns .goto-detail {
    right: -8px;
    left: unset;

  }
  .ar-AR .join-now {
    right: 0;
  }
  .ar-AR .task-detail-new .detail-info {
    padding: 10px 0px 5px 5px;
  }
  .ar-AR .campaign-task-step {
    padding: 5px;
  }
  .ar-AR .task-deadline {
    margin-left: unset;
    margin-right: unset;
  }
  .ar-AR .post-example-wrapper {
     margin-left: unset;
     margin-right: unset;
  }
  .ar-AR .task-detail-new .campaign-task-step .center {
    margin-right: unset;
  }
  .ar-AR .all-campaigns .goto-detail-verified {
    right: -12px;
    left: unset;
  }
  .ar-AR .email .code-btn {
    border: 1px solid #ccc;
  }
  .ar-AR .ant-modal-centered .ant-modal {
    text-align: right;
  }

  .ar-AR .how-to-pati .ant-timeline-item-head-custom {
    right: -15px;
    left: unset;
  }
}
