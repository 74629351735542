/* @font-face {
  font-family: 'RobotoLight';
  src: url('./assets/font/Roboto-Light.ttf');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoNormal';
  src: url('./assets/font/Roboto-Regular.ttf');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoBold';
  src: url('./assets/font/Roboto-Bold.ttf');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
} */
 @font-face {
   font-family: 'Robot';
   src: url('./assets/font/Roboto-Light.ttf') format("truetype");
   font-weight: 200;
 }

 @font-face {
   font-family: 'Robot';
   src: url('./assets/font/Roboto-Regular.ttf') format("truetype");
   font-weight: 500;
 }

 @font-face {
   font-family: 'Robot';
   src: url('./assets/font/Roboto-Medium.ttf') format("truetype");
   font-weight: 600;
 }

 @font-face {
   font-family: 'Robot';
   src: url('./assets/font/Roboto-Bold.ttf') format("truetype");
   font-weight: 800;
 }

   @font-face {
    font-family: 'Robot';
    src: url('./assets/font/Roboto-BoldItalic.ttf') format("truetype");
    font-weight: bold;
    font-style: italic;
   }





html,body {
  margin: 0;
  padding: 0;
  font-family: 'Robot'!important;
  /* font-size: 1rem; */
  line-height: 1.5;
  background-color: #fff;
}

/*
 ar css
 **
 */

.ar-AR {
  direction: rtl;
}

.ar-AR .campaigns-item .item-center {
  padding: 8px 24px 8px 0;
}
.ar-AR .campaigns-item .like-it {
  border-radius: 50px;
  font-size: 13px;
  color: #999;
  display: inline-block;
  padding: 1px 10px;
  text-align: center;
  cursor: pointer;
  bottom: 28px;
  left: 557px;
  white-space: nowrap;
  position: absolute;
  right: unset;
}
.ar-AR .contact-us .select-container .language-icon {
  margin-right: unset;
  margin-left: 7px;
}
.ar-AR .contact-us .select-container .selectTri {
  margin-right: 7px;
  margin-left: unset;
}
.ar-AR .login-password-container .ant-input-affix-wrapper {
  padding: 4px 0 4px 11px;
}
.ar-AR .login-password-container .ant-input-prefix {
  margin-left: 11px;
  margin-right: unset;
}
.ar-AR .email .code-btn {
  right: unset;
  left: 0;
}
.ar-AR .email .code-btn {
  border-right: 1px solid #ccc;
  border-left: unset;
}
.ar-AR .email .code-btn-disable {
  right: unset;
  left: 0;
  border-right: 1px solid #ccc;
  border-left: unset;

}


.ar-AR .join-now {
  left: -18px;
  right: unset;
}
.ar-AR .like-it .anticon-heart {
  margin-left: 7px;
}

.ar-AR .campaigns-detail .campaign-id{
  display: flex;
}
.ar-AR  .task-detail-new  .detail-info {
  padding: 0 25px 40px 50px;
}
.ar-AR .campaign-task-step {
  padding: 22px 26px 22px 90px;
}
.ar-AR .campaign-type {
  display: flex;
}
.ar-AR .campaign-id-con {
  margin-right: 10px;
}
.ar-AR .campaign-duration {
  display: flex;
}
.ar-AR .campaign-duration .dura-txt {
  margin-left: 10px;
  margin-right: unset;
}
.ar-AR .campaigns-item .average-earn .rewards-aver {
  margin-right: 15px;
  margin-left: unset;
}
.ar-AR .title-h {
  padding-right: 10px;
  padding-left: unset;
}
.ar-AR .title-h::after {
  right: -3px;
  left: unset;
}
.ar-AR .pending .pend-txt {
  margin-right: 10px;
  margin-left: unset;
}
.ar-AR .duration-time {
  margin-right: 12px;
}
.ar-AR .country-item {
  padding-right: 20px;
}
.ar-AR .tag-item {
  padding: 1px 7px 1px 0;
}
.ar-AR .free-sample {
  display: flex;
}
.ar-AR .ic-sample-icon {
  margin-top: 0;
}
.ar-AR .average-earn {
  display: flex;
  align-items: center;
}
.ar-AR .earn-money {
  margin-right: 10px;
}
.ar-AR .box-wrapper .like-it {
  right: 49px;
  left: unset;
}
.ar-AR .all-campaigns .goto-detail {
  left: -20px;
  right: unset;
}
.ar-AR .all-campaigns .goto-detail-verified {
  left: -20px;
  right: unset;
}
.ar-AR .campaigns-detail .s-p-order {
  padding-right: 18px;
}
.ar-AR .sample-apply-btn {
  margin-left: auto!important;
  margin-right: 0;
}
.ar-AR .task-deadline {
  margin-right: 85px;
}
.ar-AR .post-example-wrapper {
  margin-right: 83px;
}
.ar-AR .task-detail-new .campaign-task-step .center {
  right: 110px;
  margin-right: 30px;
}
.ar-AR .profile-layout-l{
  margin-right: 220px;
  margin-left: 0;
}
.ar-AR .profile-layout-l>.ant-layout {
  margin-right: 20px;
}
.ar-AR .right-info .info-title {
  text-align: right;
}
.ar-AR .profile-nav {
  margin-right: 0;
  margin-left: 14px;
}

.ar-AR .menu-w-i .arrow-down {
  left: 32px;
  right: unset;
}
.ar-AR .profile-nav .ar-nav-item {
  display: flex;
  align-items: center;
  padding: 0 34px 0 24px !important;
}
.ar-AR .profile-nav .ant-menu-submenu-title {
  display: flex;
  padding: 0 34px 0 24px!important;
}
.ar-AR .profile-nav .my-account-img {
  margin-right: 0;
  margin-left: 16px;
}
.ar-AR .profile-nav .my-earning-img {
  margin-right: 0;
  margin-left: 16px;
}
.ar-AR .profile-nav .ant-menu-sub >.ant-menu-item {
  padding-left: unset;
  padding-right: 63px!important;
  text-align: right;
}
.ar-AR .war-title {
  border-right: 2px solid #f0774a;
  border-left: unset;
}
.ar-AR .edit-txt {
  margin-right: 7px;
  margin-left: unset;
}

.ar-AR .personal-info .ant-form-item-label {
  display: flex;
  margin-left: unset;
  margin-right: 40px;
}
.ar-AR .edit-password .edit-password-txt {
  margin-right: 6px;
  margin-left: unset;
}
.ar-AR .address-operation img {
  margin-left: 5px;
  margin-right: 20px;
}
.ar-AR .address-item {
  padding: 30px 30px 0 0;
}
.ar-AR .bank-account .now-card-paypal {
  padding: 30px 30px 10px 20px;
}
.ar-AR .card-info .card-cont {
  margin-right: 20px;
}
.ar-AR .del-edit {
  left: 70px;
  right: unset;
}
.ar-AR .del-wrapper {
  margin-left: 60px;
}
.ar-AR .del-wrapper img,
.ar-AR .edit-wrapper img {
  margin-left: 10px;
  margin-right: 0;
}
.ar-AR .add-card-txt {
  margin-right: 10px;
}
.ar-AR .add-bank-account .ant-form-item-label {
  text-align: right;
}
.save-btn-submit .ant-form-item-control-input-content {
  text-align: center;
}
.ar-AR .data-preview .data-info {
  margin-right: 10px;
  margin-left: unset;
}
.ar-AR .data-info-total {
  margin-right: 10px;
  margin-left: unset;
}
.ar-AR .history-list .status-txt {
  margin-left: unset;
  margin-right: 6px;
}
.ar-AR .my-sample .campaigns-item .item-center {
  padding: 29px 24px 29px 0px;
}
.ar-AR .my-sample .ic_sample {
  margin-left: 12px;
  margin-right: unset;
}
.ar-AR .my-sample .sample-status-value {
  margin-right: 10px;
  margin-left: unset;
}

.ar-AR .campaigns-detail .box-wrapper {
  padding: 32px 25px 100px 32px;
}
.ar-AR .campaigns-detail .detail-info {
  padding: 0 25px 40px 50px;
}





.ar-AR .my-profile-mobile .item-go {
  transform: rotate(180deg);
}
.ar-AR .my-profile-mobile .menu-item {
  padding: .4rem .4rem .4rem .2rem;
}
.ar-AR .my-profile-mobile .menu-item .menu-icon {
   margin-left: .3rem;
   margin-right: 0; 
}
.ar-AR .my-profile-info-mobile .form-val {
  margin-right: 0;
  margin-left: .2rem;
}


.ar-AR .change-language .language-item {
  padding: .28rem .4rem .28rem 0;
}
.ar-AR .change-language .select-l-icon {
  left: .4rem;
  right: unset;
}

.ar-AR .contact-us .body-download {
  margin-left: 8px;
}

.ar-AR .campaigns-detail-mobile .box-wrapper .like-it {
  left: 27px;
  right: unset;
}
.ar-AR .campaigns-detail-mobile .title-h::after {
  right: -3px;
  left: unset;
}
.ar-AR .campaigns-detail-mobile .step-item {
  padding: 10px 15px 10px 6px;
}
.ar-AR .campaigns-detail-mobile .step-name {
  padding-right: 10px;
  padding-left: unset;
}
.ar-AR .campaigns-detail-mobile .step-item img {
  margin-left: 10px;
  margin-right: unset;
}
.ar-AR .campaigns-detail-mobile .step-item .step-des {
  text-align: right;
}
.ar-AR .campaigns-detail-mobile .title-h {
  padding-right: 10px;
  padding-left: unset;
}
.ar-AR .campaigns-detail-mobile .des-detail ul {
  padding-right: .3rem;
  padding-left: unset;
}
.ar-AR .link-cont {
  padding-right: unset;
}
.ar-AR .task-progress .progress-img {
  transform: rotateY(180deg);
}
.ar-AR .task-progress-wrapper {
  transform: rotate(180deg);
}
.ar-AR .task-progress-item-icon {
  transform: rotate(180deg);
}
.ar-AR .register .agree {
  margin-right: -23px;
}
.ar-AR .agree .reg-check-icon {
  margin-left: 10px;
  margin-right: unset;
}




.ar-AR .ant-modal-close {
  left: 0;
  right: unset;
}
.ar-AR .sample-content .sample-se-name {
  margin-left: unset;
  margin-right: 8px;
}
.ar-AR .follow-rule .rule-item {
  margin-right: 10px;
  margin-left: unset;
}
.ar-AR .sample-apply-modal .rule-content {
  margin-right: 15px;
  margin-left: unset;
}

.ar-AR .am-modal-wrap .close-icon {
  left: 10px;
  right: unset;
}


.ar-AR .ic-in-progress {
  transform: rotate(180deg);
}
.ar-AR .ant-progress-line {
  transform: rotate(180deg);
}
.ar-AR .card-num-n {
  margin-right: 10px;
  margin-left: unset;
}
.ar-AR .gray-icon {
  margin-right: -4px;
  margin-top: 4px;
}


.ar-AR .how-earn-rule .how-txt1 {
   text-align: right;
   padding-right: 15px;
}
.ar-AR .how-earn-rule .how-txt1::before {
  right: 0;
  left: unset;
}

.ar-AR .how-to-pati .ant-timeline-item-head-custom {
  right: -34px;
  left: unset;
}
.ar-AR .how-to-pati .ant-timeline-item-tail {
  right: 3px;
  left: unset;
}
.ar-AR .ant-timeline-item-content {
  margin: 0 26px 0 0 ;
}


button:disabled {
  opacity: 0.5;
}

::-webkit-scrollbar {
  height: 0px;
  width: 1px;

}
div::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.ml-1{
  margin-left: 10px;
}
.ml-2{
  margin-left: 20px;
}
.link-item {
  color: #fff;
}

.point-class {
  cursor: pointer;
}

.normal-link {
  color: #000;
}
.opa1 {
  animation: opacity1 1.5s ease-in-out;
}
.theme-color {
  color: #f0774a;
}
.swiper-modal .ant-modal-body {
  padding: 0;
}
@keyframes opacity1 {
   from {opacity: 0;}
   to {opacity: 1;}
}

.ant-input {
  border-radius:4px;
  padding-left: 6px;
}

.ant-btn {
  border-radius: 0;
}

.ant-back-top {
  bottom: 130px;
  z-index: 99999;
}

.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}

.ant-btn-primary {
  background: #f0774a;
  border-color: #f0774a;
}

.search-wrapper:hover {
  border: 1px solid #f0774a;
}

.search-w-focus {
  border: 1px solid #f0774a;
  box-shadow: 0 0 0 2px rgb(240 119 74 / 20%);
}

.load-more-wr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}

.load-more-wr .load-more-sp {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 12px 24px 12px 24px;
  background: linear-gradient(to right, #F79C37, #F0774A);
  border-radius: 100px;
  display: inline-block;
  cursor: pointer;
}
.ant-modal-mask,
.ant-image-preview-mask,
 .ant-image-preview-mask{
   background-color: rgba(240,240,240,.65);
 }

 .ant-notification{
   right: 50% !important;
   transform: translateX(50%);
   margin-right: unset;
 }

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  /* background-color: #f9c9b7;
  border-color: #f9c9b7; */
  background: #f0774a;
  border-color: #f0774a;
}

.ant-input:hover {
  border-color: #f0774a;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #f0774a;
  box-shadow: 0 0 0 2px rgba(240, 119, 74, 0.2);
}

.ant-input-affix-wrapper:hover {
  border-color: #f0774a;
  z-index: unset;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #f0774a;
  box-shadow: 0 0 0 2px rgba(240, 119, 74, 0.2);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #f0774a;
}

.ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
  box-shadow: 0 0 0 2px rgba(240, 119, 74, 0.2);
}

.ant-radio-checked .ant-radio-inner {
  border-color: #f0774a;
}

.ant-radio-checked::after {
  border: 1px solid #f0774a;
}

.ant-radio-inner::after {
  background-color: #f0774a;
}

.account-form .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f0774a;
  border-color: #f0774a;
  border-radius: 100px;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #f0774a;
}

.ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
  border-color: #f0774a;
}
.ant-menu-sub.ant-menu-inline{
  background: #fff;
}

.ant-menu-submenu-selected{
  color: #000;
}
.ant-menu-item-selected{
  color: #f0774a;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: #fff;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover{
  color: #f0774a;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after{
  /* border: none; */
  border-color: #f0774a;
}
.ant-menu-submenu-vertical>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before{
  background:#f0774a;
}
.ant-menu{
  font-size: 12px;
}
a:active,
a:hover {
  color: #f0774a;
}

.ant-btn:hover,
.ant-btn:focus{
  background: linear-gradient(to right, #F79C37, #F0774A);
  color: #fff;
}

.ant-input-affix-wrapper:hover{
  /* border-color: #d9d9d9; */
  border-color: #f0774a;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused{
  /* border-color: #d9d9d9; */
  border-color: #f0774a;
}
/* .ant-pagination-item-active a {
  color: #f0774a;
}

.ant-pagination-item-active {
  border-color: #f0774a;
} */

.ant-form-item-label>label::after{
  content: '';
}
.ant-form-item-label>label{
  font-size: 13px;
}

.app .header {
  background-color: #fff;
  padding: 0;
  height: unset;
}
.header-white {
  box-shadow: 0px 2px 13px 0px rgba(228, 228, 228, 0.6);
}
.header-black .contact-us {
  background-color: rgb(30,26,27);
  color: #fff;
  
}

.header-white .contact-us {
  background-color: #fff;
}

.contact-us .contact-body {
  max-width: 1200px;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 300;
  display: flex;
  justify-content: flex-end;
  height: 38px;
  line-height: 38px;
}
.header-white-logo {
  height: 20px;
}


.contact-us .select-container {
  display: inline-block;
  padding: 0 18px;
  position: relative;
  cursor: pointer;
}

.header-white .contact-us .select-container{
  color: #666;
}
.header-white .app-header-new .select-container {
  height: 41.3px;
  line-height: 41.3px;
}

.contact-us .select-container span {
   font-size: 14px;
   font-weight: 400;
}
.contact-us .select-container img {
  width: 14px;
  margin-left: 4px;
}
.contact-us .select-container .language-icon {
  vertical-align: inherit;
  margin-right: 7px;
}
.contact-us .select-container .selectTri {
  /* transform: translateY(-44%); */
  margin-left: 7px;
  vertical-align: revert;
}
.contact-us .select-container:hover .selectTri {
  transform: rotate(180deg);
  transition: all .2s;
}
.contact-us .contact-body .select-container .selectTri-s {
  transform: rotate(180deg);
  transition: all .2s;
}
.contact-us .contact-body .select-container .selectTri-h {
  transform: rotate(0deg);
  transition: all .2s;
}

.contact-us .select-container:hover .selected-language{
  /* color: #fff; */
}
.contact-us .select-container .language-menu {
  /* width: 137px; */
  min-width: 180px;
  /* background-color: #fff; */
  background-color: #1e1a1b;
  position: absolute;
  /* color: #333; */
  color: #fff;
  top: 50px;
  right: 16px;
  z-index: 9999;
  box-shadow: 0 12px 60px rgb(14 32 66 / 15%),
  0 1px 2px rgb(14 32 66 / 5%);
  /* padding: 5px 10px 5px 10px; */
  opacity: 0;
  transition: all .5s;
  height: 0px;
  overflow: hidden;
}
.app-header-new .select-container .language-menu {
  right: -10px;
}

.header-white .select-container .language-menu{
  background-color: #fff;
  color: #666;
}

.header-white .contact-us .language-menu .language-select-item {
  color: #666;
}

.contact-us .select-container:hover .language-menu{
  opacity: 1;
  /* margin-top: 10px; */
  top: 38px;
  height: unset;
}

.app-header-new .select-container:hover .language-menu{
  opacity: 1;
  top: 42px;
  right: -10px;
  left: unset;
}
.login-password-container .email-code-wraper .ant-btn:hover,
.login-password-container .email-code-wraper .ant-btn:focus {
  background: none;
}


.contact-us .contact-body .select-container .language-menu-show {
   opacity: 1;
   height: unset;
}

.contact-us .contact-body .select-container .language-menu-hidden {
  height: 0px;
  opacity: 0;
}
.contact-us .con-header-body .select-container .language-menu-hidden {
  height: 0px;
  opacity: 0;
}


.language-menu .language-select-item{
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 38px;
  line-height: 34px;
  position: relative;
}

.language-select-item .flag-icon{
  position: absolute;
  left: 4px;
}

.select-container .language-menu .language-select-item:hover {
  color: #f79c37;
  /* background-color: #fbf2ee; */
  background: rgba(255, 255, 255, 0.1);
}
.header-white .contact-us .language-menu .language-active {
  color: #f79c37;
  /* background-color: #fbf2ee; */
  background: rgba(255, 255, 255, 0.1);
}
.header-black .contact-us .language-menu .language-active {
  color: #f79c37;
  /* background-color: #fbf2ee; */
  background: rgba(255, 255, 255, 0.1);
}

.contact-us .body-download {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: #fff;
  margin-right: 8px;
}
.contact-us .body-download .open-app-btn {
  background: linear-gradient(to right, #F79C37, #F0774A);
  padding: .1rem .2rem; 
  border-radius: 3px;
  font-size: .24rem;
}

.contact-email {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
}

.con-login-singup{
  display: none;
}

.contact-email .contact-e {
  width: 17px;
  margin-right: 8px;
}
.contact-email .contact-txt{
  transform: scale(.93);
}

.contact-us .select-container select {
  border: none;
  background-color: rgb(234,232,232);
  position: auto;
  height: 100%;
  padding: 8px 35px;
  appearance: none;
  -moz-appearance: none;
  text-align: center;
  -webkit-appearance: none;
  transform: scale(.93);
}

.header-black .select-container select {
  /* background-color: rgb(64, 60, 61); */
}



.contact-us .anticon-mail {
  vertical-align: middle;
  margin-right: 6px;
  margin-top: -2px;
}

.header .header-account {
  max-width: 1000px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  padding: 15px 18px 15px 18px;
}

.header-account .logo-img {
  width: 170px;
}

.header-account .logo-img img {
  width: 100%;
}

.user-header .my-account {
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-account .header-arrow {
  display: none;
}
.my-account .nav-header-d{
  display: flex;
  align-items: center;
}


.my-account .header-img {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  border-radius: 50%;
  border: 1px solid #eee;
}
.my-account .account-email{
  font-size: 12px; 
  transform: scale(.94);
}
.my-account .ant-dropdown-link{
  color: #f0774a;
}

.main-logo {
  height: 40px;
  margin-right: 40px;
  cursor: pointer;
}

.nav-bar {
  /* background-color: rgb(249, 249, 249); */
  box-shadow: 0px 0px 13.5px rgba(0, 0, 0, .1);
  background-color: #fff;
}

.nav-bar .nav-con {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-con .nav-menu {
  display: flex;
  align-items: center;
  z-index: 999;
  background-color: #fff;

}

.nav-con .nav-item {
  color: #000;
  width: 150px;
  padding: 23px 0;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
  font-weight: 600;
  position: relative;
  white-space: nowrap;
}
.nav-con .nav-item .nav-link{
  font-size: 17px;
  transition: color .3s;
}

.nav-con .nav-item .nav-link:hover{
  color: #f0774a;
}

/**
  * hover highlight
 **/ 
.high-light-text {
   transition: color .3s;
} 
.high-light-text:hover{
   color: #f0774a!important;
}


.login-signup {
   cursor: pointer;
}
.login-signup .login-s {
  color: #f0774a;
  font-size: 14px;
  font-weight: bold;
}
.login-signup .gray-line {
  margin: 0 10px;
  height: 20px;
  display: inline-block;
  border-right: 1.5px solid #999;
  vertical-align: middle;
}
.login-signup .signup-s {
  color: #000;
  font-size: 14px;
  font-weight: bold;
}




.nav-con .nav-item-active {
  color: #f0774a;
  position: relative;
}
.nav-con .nav-item-active .ant-badge{
  color: #f0774a;
}
/* #messageDot::after{
  content: '';
  width: 6px;
  height: 6px;
  background-color: #f0774a;
  border-radius: 50%;
  display: inline-block;
  top: 20px;
  position: absolute;
  right: 22px;
} */


.loading {
  text-align: center;
  color: #f0774a;
}

.loading .ant-spin-dot-item {
  background-color: #f0774a;
}
.img-evidence .ant-spin {
  color: #f0774a;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.ant-select-item{
  font-size: 13px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(240, 119, 74, .2)
}

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
 
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-color: #f0774a;
  box-shadow: 0 0 0 2px rgba(240, 119, 74, .2)
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #f0774a;
}

.hide {
  display: none;
}

.ant-layout{
  background-color: #fff;

}




.app-footer {
  z-index: 999;
  
}
.app-footer .footer-gray {
  /* background-color: rgb(245,242,240); */
  background-color: #111114;
  position: relative;
  bottom: -10px;
}
.app-footer .footer-black {
  background-color: #111114;
  text-align: center;
  color: #fff;
  padding: 20px 0 10px 0;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-gray .footer-g-content {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px 0;
}
.footer-link-2 {
  margin-left: 40px;
}
.footer-g-content .cont-left {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cont-left .logo-ab {
  height: 18px;
}
.cont-left .ic-sx {
  height: 30px;
  margin: 0 10px;
}
.cont-left .logo {
  height: 40px;
}
.cont-right {
  display: flex;
  align-items: center;
} 
.cont-right .follow-us {
  font-size: 14px;
  margin-right: 10px;
  color: #fff;
}
.linkedin-link, .ins-link {
  display: inherit;
  margin-right: 10px;
}
.cont-right .logo-fb {
   height: 35px;
   margin: 0 5px;
}
.cont-right .logo-google {
   height: 35px;
} 

.cont-right .ins-logo-footer {
   color: #707a8a;
   font-size: 17px;
}
.cont-right .linkin-logo-footer {
  color: #707a8a;
  font-size: 17px;
}
.cont-right .ins-logo-footer:hover {
   color: #fff; 
}
.cont-right .linkin-logo-footer:hover {
  color: #fff;
}




.input-warning {
  border-color: #f0774a;
}
.input-warning-txt {
  color: #ff4d4f;
  font-size: 12px;
  transform: scale(.9);
  margin-left: -10px;
}





.ant-checkbox-checked::after{
  border:1px solid #f0774a;
}
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #f0774a;
    border-color: #f0774a;
}



/**
  message nav
*/

.message-nav {
  /* width: 171px; */
  width: 220px;
  /* height: 540px; */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, .06);
  border: 1px solid #f4f4f4;
  margin-right: 10px;
  padding-top: 30px;
  max-height: 500px;
  border-radius: 8px;
  position: fixed;
}

.app-inative  .message-nav{
  top: 10px;
  position: fixed;
}

.message-menu-list {

}

.message-menu-item {
  margin-bottom: 30px;
  font-size: 14px;
  color: #666;
  cursor: pointer;
  font-weight: bolder;
  position: relative;
  display: flex;
  align-items: center;
}
.m-item-active{
  color: #f0774a;
  border-right: 2px solid #f0774a;
}

.message-menu-item .m-menu-name {
  padding: 4px 15px 4px 35px;
}
.message-menu-item .m-nums {
  background-color: #f0774a;
  color: #fff;
  border-radius: 100px;
  padding: 0px 11px;
  font-size: 12px;
  transform: scale(.8);
  height: 20px;
  line-height: 20px;
}

.message-menu-item:hover{
  font-weight: bolder;
  /* color: #f0774a; */
}



.region-select {
  color: #f0774a;
  font-size: 17px;
  margin-right: 5px;
  margin-top: -3px;
}

.sample-apply-modal .ant-modal-content{
  border-radius: 10px;
}

.sample-apply-modal  .sample-modal-header{
    font-size: 21px;
    font-weight: 600;
}
.sample-ins-wrapper {
  background-color: #F8F9FC;
  padding: 22px 17px 22px 17px;
  margin-top: 10px;
}
.sample-ins-wrapper .ins-connect-w {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ins-connect-w .ins-connect-btn{
  border-radius: 4px;
  border: 1px solid rgba(225, 224, 227, 1);
  font-size: 16px;
  padding: 0 8px;
  cursor: pointer;
}
.sample-ins-wrapper .ins-label {
    color: #1E191A;
    font-size: 17px;
    font-weight: 600;
}
.sample-ins-wrapper .ins-value {
  color: #1E191A;
  font-size: 15px;
  margin-top: 10px;
}
.sample-ins-wrapper .ins-input {
  margin-top: 7px;
}
.sample-selected-wrapper {
   margin-top: 16px;
}
.sample-selected-wrapper .sample-s-header{
  font-size: 18px;
  margin-bottom: 7px;
}
.sample-selected-wrapper .sample-content{
   display: flex;
   align-items: center;
}
.sample-content .sample-pic {
  width: 62px;
  height: 62px;
  object-fit: contain;
}
.sample-content .sample-se-name {
  font-size: 16px;
  color: #666;
  font-weight: 400;
  margin-left: 8px;
}
.sample-apply-modal .follow-rule .term {
  font-size: 18px;
  color: #1E191A;
}
.follow-rule .rule-item {
   margin-top: 18px;
   margin-left: 10px;
}
.sample-apply-modal .rule-index {
   background-color: #eee;
   border-radius: 50%;
   width: 20px;
   height: 20px;
   line-height: 20px;
   display: inline-block;
   text-align: center;
}
.sample-apply-modal .rule-content {
   font-size: 15px;
   margin-left: 15px;
}
.sample-apply-modal .check-term {
  text-align: center;
  padding: 30px 0 15px;
}
.sample-apply-modal .submit-button{
  width: 380px;
  margin: 0 auto;
}
.sample-apply-modal .submit-button .save-btn {
  height: 40px;
}


/**
 profile layout
*/
.profile-layout {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  /* position: relative; */
  /* top: 89px; */
  padding-top: 130px;
}
.profile-layout-l {
  margin-left: 220px;
}
.profile-layout-l >.ant-layout {
  margin-left: 10px;
}

.profile-nav {
  width: 220px;
  height: 660px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, .06);
  margin-right: 14px;
  border-radius: 12px;
  overflow: hidden;
  border-bottom: 1px solid #F4F0EE;
}
.profile-nav .ant-menu-submenu-title{
  margin-bottom: 14px;
  font-weight: 600;
  font-size: 14px;
  position: relative;
}

.profile-nav .ant-menu-item{
  font-size: 13px;
  padding-left: 63px!important;
}
.profile-nav .nav-my-earn{
  padding-left: 24px!important;
  font-size: 14px;
  font-weight: 600;
}

.profile-nav .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  font-weight: 600;
}

.profile-nav .ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after{
  height: 32px;
  margin-top: 4px;
}

.profile-nav .ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left{
  border-right: none;
}
.profile-nav .ant-menu-submenu-arrow {
  display: none;
}

.nav-header {
   background-image: url('https://cdn.firstlinkapp.com/upload/2020_10/1603261927396_80930.jpg');
   background-size: cover;
   position: relative;
   width: 100%;
   /* min-height: 171px; */
   height: 220px;

   margin-bottom: 75px;

}

.edit-header{
  position: absolute;
  border-radius: 50%;
  top: 10px;
  left: 10px;
}


.edit-header .edit-header-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.nav-h-name {
  background: url('./imgs/nav_h_name.png') center no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
  height: 117px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
.n-h-name {
   font-size: 19px;
   font-weight: 600;
   margin-bottom: 7px;
}
.n-h-email {
  font-size: 13px;
  color: #666;
}
.my-account-img {
  width: 22px;
  margin-right: 16px;
}
.my-earning-img {
  width: 22px;
  margin-right: 16px;
  vertical-align: sub;
}

.menu-w-i .arrow-down {
  width: 13px;
  height: 6px;
  position: absolute;
  right: 32px;
  top: 17px;

}
.heade-n-arrow{
  width: 15px;
  height: 15px;
  margin-left: 4px;
  cursor: pointer;
}

.my-account-img-1 {
  width: 15px;
  margin-top: -4px;
  margin-right: 10px;
}

.logout-img {
  width: 15px;
  margin-top: -4px;
  margin-right: 10px;
}

.war-title{
  font-weight: 600;
  border-left: 2px solid #f0774a;
  padding: 0px 10px;
}

.fixed-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.app-inative .fixed-bar {
  opacity: 0;
  -webkit-transition: .8s;
  transition: .8s;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);

}

.ins-connect{
  background-color: rgb(251, 249, 250);
  text-align: right;
  padding: 5px 10px 5px 0;
}

.load-more-wr .ant-spin-dot-item {
  background-color: #fff;
}
.load-more-wr .loading-text {
  margin-left: 10px;
}


.default-layout-app{
  /* padding-top: 110px; */
}
.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon{
  color: #C6C6C6;
  display: none;
}

.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input-affix-wrapper-focused{
  /* box-shadow: none; */
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused{
  /* box-shadow: none; */
}
.ant-input:focus,
.ant-input-focused{
  /* box-shadow: none; */
  /* border-color: #d9d9d9; */
}
.ant-input:hover{
  /* border-color: #d9d9d9; */
}

.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix{
  padding-right: 0;
}
.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon{
  right: -35px;
}

.ant-dropdown{
  -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title{
  padding: 5px 55px 5px 20px;
}
.ant-dropdown-menu{
  border-radius: 6px;
  border: 1px solid #FAE8E1;
  padding: 10px 0;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover{
      /* background-color: #fff; */
      /* border-left: 2px solid #f0774a; */
      color: #f0774a;
}
.ant-dropdown{
   
}
.ant-dropdown-placement-bottomRight>.ant-dropdown-arrow{
      right: 16px;
      border: 1px solid #FAE8E1;
      z-index: -1;
}

.ant-message{
  top: 90px;
}
.ant-message-notice-content{
  background-color: #999;
  opacity: .7;
  color: #fff;
  border-radius: 8px;
  padding: 8px 16px;
}
.ant-message .anticon{
  display: none;
}

img{
  opacity: 1;
  transition: opacity .3s ease-in;
}


.ant-skeleton{
  margin-bottom: 10px;
}
.ant-skeleton-header{
  display: block;
  width: 360px!important;
  height: 200px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle{
  border-radius: 0!important;
  width: 100%;
  height: 100%;
}

.my-sample-mobile .ant-skeleton-header{
  width: unset!important;
  height: unset;
  display: table-cell;
}
.my-sample-mobile .ant-skeleton-content{
  display: table-cell;
}
.my-sample-mobile .ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle{
  width: 78px;
  height: 78px;
}


.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background-color: #FAE8E1;
}
.ant-cascader-picker:focus .ant-cascader-input {
  border-color:#f0774a;
  box-shadow:none;
}

.ant-back-top-content{
  color: rgba(238, 107, 25, .87);
  background: #fff;
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / 10%);
}
.ant-back-top-content:hover{
   opacity: 1;
   background: #fff;
}
.ant-back-top-content .ant-back-top-icon{
  font-size: 18px;
}

@media only screen and (max-width: 640px) {

  .ant-input{
    font-size: .3rem;
  }


  .sample-apply-modal .submit-button {
    width: 260px;
    margin: 0 auto;
  }


  .contact-us .contact-body{
    justify-content: space-between;
  }
  .contact-body .contact-email {
    margin-left: 15px;
  }
  .contact-body .select-container {
    margin-right: 10px;
    padding: 2px 6px 2px 6px;
  }
  .select-container select {
    border-radius: 100px;
  }

  .header-account .logo-img {
    width: 120px;
  }

  .header-account .my-account {
    flex-direction: column;
    justify-content: center;
  }

  .my-account .account-email {
     font-weight: 600;
     transform: scale(.89);
  }

  .profile-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    top: 80px;
    padding-top: 0;
  }
  .profile-layout-l {
    margin-left: unset;
  }
  .profile-layout-l>.ant-layout {
    margin-left: unset;
  }

  .messages-layout {
    top: 125px;
  }

  .nav-bar {
    box-shadow: 0 0 black;
  }

  .nav-bar .nav-con{
    flex-direction: column;
    align-items: end;
    display: flex;
    flex-direction: column-reverse;

  }
  .nav-con .nav-menu{
    width: 100%;
    position: relative;
    top: -15px;
    border-top-right-radius: 56px;
    box-shadow: 0px -6px 10px 0px rgba(30, 25, 26, .09);
  }
  .nav-con .nav-menu[isaccount="true"] {
    top: 0;
  }

  .nav-con .my-profile {
    width: 100%;
    margin-top: 10px;
  }


  .nav-con .nav-menu img {
    display: none;
  }

  .profile-nav{
     width: 100%;
     height: unset;
     border-bottom: 10px solid #f4f4f4;
     position: unset;

  }
  .profile-nav .ant-menu{
    
  }
  .profile-nav .ant-menu-root {
    display: flex;
    position: relative;
    padding-bottom: 9px;
    overflow: hidden;
  }
  .profile-nav .ant-menu-root::before{
    content: '';
    height: 20px;
    border-right: 1px solid #999;
    position: absolute;
    left: 50%;
    top: 15px;
  }
  .profile-nav .ant-menu-submenu{
    flex: 1;
  }
  .profile-nav .ant-menu-item{
    padding-left: 30px!important;
  }
  .profile-nav .ant-menu-submenu-title{
    padding-left: 0px!important;
    margin-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    text-align: center;

  }

  .profile-nav .menu-w-i .arrow-down{
    width: 10px;
    position: unset;
    margin-left: 4px;
  }

  .profile-nav .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after{
    border: none;
  }
  .profile-nav .ant-menu-sub.ant-menu-inline>.ant-menu-item,
  .ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title{
    margin-top: 0;
    margin-bottom: 0;
    height: 30px;
  }

  .profile-nav .my-account-img{
    width: 18px;
    margin-right: 14px;
  }
  .profile-nav .my-earning-img{
    width: 18px;
    margin-right: 14px;
  }

  .profile-nav .my-earn-nav .arrow-down{
    margin-left: 7px;
  }

  .nav-header{
        width: 100px;
        margin: 12px auto 0;
        height: 100px;
        position: relative;
        margin-bottom: 60px;
  }
  .edit-header{
    left: -100px;
  }
  .default-layout-app{
    /* padding-top: 140px; */
  }

  .message-nav{
    margin-right: unset;
    width: 100%;
    padding-top: 0;
    box-shadow: 0px -5px 8px 0px rgba(30, 25, 26, .09);
    border: none;
    border-top-left-radius: 27px;
    border-top-right-radius: 0;
    position: fixed !important;
    z-index: 999;
    background-color: #fff;
  }
  .app-inative .message-nav {
    top: 0px;
    position: fixed;
  }
  .message-menu-list{
    overflow: hidden;
    padding: 10px 14px 10px 14px;
    height: 40px;

  }
  .m-over {
    display: flex;
    overflow-x: scroll;
    padding-bottom: 10px;
    /* -webkit-overflow-scrolling: touch; */
  }
  .message-menu-list .m-item-active{
    border-right: none;
    position: relative;
    background-color: rgb(253,239,233);
    color: #f0774a;
    /* border-bottom: 2px solid #f0774a; */
  }
   /* .message-menu-list .m-item-active::after{
     width: 50px;
     height: 2px;
     content: '';
     display: block;
     background-color: #f0774a;
     position: absolute;
     left: 50%;
     transform: translateX(-50%);
     bottom: -4px;

   } */

   .message-menu-item:hover {
     /* color: #666; */
   }


  .user-header .my-account{
    padding: 3px 10px 17px 10px;
    background-color: #E7D4CC;
    position: relative;
    /* top: 20px; */
    margin: 0px 15px 0 15px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    justify-content: flex-start;

  }

  .my-account .header-arrow {
    width: 16px;
    position: absolute;
    right: 20px;
    display: block;
  }

  .my-account .header-img{
    width: 34px;
    height: 34px;
    border: 2px solid #F4EBE8;

  }

  .user-header .heade-n-arrow{
     display: none;
  }
  .message-menu-item{
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
     border-top-left-radius: 14px;
     border-top-right-radius: 20px;
     border-bottom-right-radius: 20px;
  }
  .message-menu-item .m-menu-name{
    font-size: 12px;
    white-space: nowrap;
    transform: scale(.95);
    padding: 6px 10px 6px 10px;
   

  }
  .nav-h-name{
     height: 30px;
     background: none;
     bottom: -55px;
     width: 200px;
     left: 50%;
     margin-left: -100px;
  }
  .nav-con .nav-item{
    font-size: 13px;
    padding: 15px 0 0 0;
    position: relative;
  }
  .nav-con .nav-item::before {
    content: '';
    display: block;
    height: 20px;
    border-right: 1px solid #eae6e4;
    position: absolute;
    bottom: 0;
  }
  .nav-con .nav-item[first="true"]::after {
   display: none;
  }


  .ant-skeleton{
    width: 80%;
    display: block;
    margin: 0 auto 20px;
  }
  .ant-skeleton-header{
    width: 100%!important;
  }
  .ant-skeleton-content{
    display: block;
  }
  .ant-form label{
    line-height: 2;
  }

  .contact-us .select-container select{
    padding: 6px 30px;
  }
  .contact-email{
    margin-right: 10px;
  }

  .nav-con .nav-item .nav-link {
    font-size: 14px;
  }





  .app-footer .footer-gray{
    padding: 0 10px 0 10px;
  }


  .load-more-wr{
    margin-top: 30px;
    margin-bottom: 30px;
  }



  .footer-gray .footer-g-content{
    width: 100%;
  }
  .cont-left .logo-ab {
    height: 14px;
  }

  .cont-left .ic-sx {
    height: 14px;
    margin: 0 5px;
  }

  .cont-left .logo {
    height: 20px;
  }
  .cont-right .follow-us {
    font-size: 12px;
  }
  .cont-right .logo-fb {
    height: 25px;
    margin: 0 5px;
  }

  .cont-right .logo-google {
    height: 25px;
  }




  .login-signup {
    cursor: pointer;
    margin: 10px 0 5px 5px;
  }

  .login-signup .login-s {
    color: #f0774a;
    font-size: 12px;
    font-weight: bold;
  }

  .login-signup .gray-line {
    margin: 0 10px;
    height: 17px;
    display: inline-block;
    border-right: 1.5px solid #999;
    vertical-align: middle;
  }

  .login-signup .signup-s {
    color: #000;
    font-size: 12px;
    font-weight: bold;
  }

  .con-login-singup{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 13px!important;
        cursor: pointer;
  }




  .message-menu-item .m-nums{
        min-width: 35px;
        padding: 0 6px;
        text-align: center;
        margin-left: -6px;
  }


  .contact-us .select-container .language-menu {
    left: 0;
  }

  .app-footer .footer-black{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px 10px 10px;
  }
  .footer-link-2 {
    margin-left: 0;
  }


  .ar-AR .my-account .header-arrow {
    left: 20px;
    right: unset;
    transform:rotate(180deg);
  }
  .ar-AR .profile-layout-l {
    margin-right: 0;
    margin-left: 0;
  }
  .ar-AR .profile-layout-l>.ant-layout {
    margin-right: unset;
  }
  .ar-AR .all-campaigns .goto-detail {
    right: -8px;
    left: unset;

  }
  .ar-AR .join-now {
    right: 0;
  }
  .ar-AR .task-detail-new .detail-info {
    padding: 10px 0px 5px 5px;
  }
  .ar-AR .campaign-task-step {
    padding: 5px;
  }
  .ar-AR .task-deadline {
    margin-left: unset;
    margin-right: unset;
  }
  .ar-AR .post-example-wrapper {
     margin-left: unset;
     margin-right: unset;
  }
  .ar-AR .task-detail-new .campaign-task-step .center {
    margin-right: unset;
  }
  .ar-AR .all-campaigns .goto-detail-verified {
    right: -12px;
    left: unset;
  }
  .ar-AR .email .code-btn {
    border: 1px solid #ccc;
  }
  .ar-AR .ant-modal-centered .ant-modal {
    text-align: right;
  }

  .ar-AR .how-to-pati .ant-timeline-item-head-custom {
    right: -15px;
    left: unset;
  }
}