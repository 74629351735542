.loading-modal .ant-modal-content {
    border-radius: 5px;
}

.loading-modal .ant-modal-body {
    padding: 0px 10px 10px 10px;
}


.language-wrapper-m {
    /* margin-top: .3rem; */
    background-color: #fff;
}

.language-item-m .flag-icon {
    width: 20px;
}

.language-item-m .flag-name {
    margin-left: 17px;
    font-size: 13px;
}

.language-item-m {
    border-bottom: 1px solid #f4f4f4;
    padding: .2rem 0 .2rem .3rem;
    position: relative;
}

.select-t-m {
    color: #000;
    font-weight: 600;
    font-size: 16px;
}

.select-l-icon-m {
    width: 20px;
    float: right;
    margin-right: 10px;

}

@media only screen and (max-width: 640px) {

    .language-item-m {
        border-bottom: 1px solid #f4f4f4;
        padding: .28rem 0 .28rem .4rem;
        position: relative;
    }

}