html,
body {
    position: relative;
    height: 100%;
}

body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}

body {
    background: #000;
}
.black-mask {
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   z-index: 9999999999;
   background: #000;

}

.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #000;
}

.swiper-slide img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
}
.black-mask .close-swiper {
    color: #fff;
    font-size: 26px;
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 999;
    cursor: pointer;
}

.black-mask .page-index {
    z-index: 999;
    color: #fff;
    font-size: 20px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 20px;

}

.black-mask .download-pic {
    color: #fff;
    z-index: 999;
    font-size: 25px;
    position: absolute;
    top: 20px;
    left: 40px;
}


@media only screen and (max-width: 640px) {
    .black-mask .close-swiper {
        color: #fff;
        font-size: 24px;
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 999;
        cursor: pointer;
    }
    .black-mask .download-pic {
        color: #fff;
        z-index: 999;
        font-size: 25px;
        position: absolute;
        top: 20px;
        left: 20px;
    }

}